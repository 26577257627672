<template>
    <div class="alert-wrapper">
        <h2 class="font-semibold text-[20px] mb-0">Alertas</h2>
            <div class="alert-wrapper__content" :class="variant()">
                <span class="span flex gap-2 text-[16px]">
                    <img :src="getImg" alt="icon" class="w-7" />
                 {{total}} fundos foram reprocessados nas últimas {{updatedAtLastHours}} horas                                  
                </span>                
                <Button
                class=""
                label="Visualizar"     
                :class="buttonVariant()"  
                @click="$emit('viewReprocessedFunds')"   
            />
               
            </div> 
           
      
    </div>
</template>

<script lang="ts" setup>
import Button from 'primevue/button';
import { defineProps, computed } from 'vue';

const props = defineProps({
	variant: {
		type: String,
		required: true
	},
	total: {
		type: Number,
		required: false
	},
	updatedAtLastHours: {
		type: Number,
	}
});


const variant = () => {
	switch (props.variant) {
	case 'warning':
		return 'warning';    
	default:
		break;
	}
};

const buttonVariant = () => {
	switch (props.variant) {
	case 'warning':
		return 'warning-button';    
	default:
		break;
	}
};

const getImg = computed(() => {
	let icon = '';

	switch (props.variant) {
	case 'warning':
		icon = 'warning-icon';            
		break;
    
	default:
		break;
	}

	const url = new URL(`../assets/svgs/${icon}.svg`, import.meta.url).href;

	return url;
});


</script>


<style lang="scss" scoped>
.alert-wrapper {
    border: solid 1px theme('colors.neutral.2');
    border-radius: 8px;
    padding: 0.2rem 1rem;
    margin-top: 1rem;
  
    &__content {
        display: flex;
        justify-content: space-between;
        align-items: center;       
    }
}


.warning {
    background: theme('colors.warning.DEFAULT');
    padding: 0.8rem 1rem;
    margin: 0.3rem 0;
    border-radius: 8px;
    width: 100%;
    color: theme('colors.warning.1');
    font-weight: 500;
}

.warning-button {
    border: solid 1px theme('colors.warning.1');
    padding: 0.2rem 1rem;
    font-weight: 500;
    &:hover {
        background: theme('colors.warning.1');
        color: theme('colors.white.DEFAULT');
    }
}
</style>