<template>
  <h2 class="text-text font-semibold text-[24px]">
    Enquadramentos {{ formatDate(route.params.date + "T00:00:00") }}
  </h2>
  <div class="mt-5 flex items-center gap-1 justify-end">
    <!-- eslint-disable-next-line no-irregular-whitespace -->
    <Button
      @click="
        router.push({
          name: 'enquadramentos',
        })
      "
      label="Voltar"
      class="global-button secondary-button"
      :disabled="loading"
    />
    <Button
      class="global-button bg-secondary-7"
      style="padding-left: 22px !important; padding-right: 22px !important"
      label=" "
      @click="downloadDialogVisible = true"
    >
      <template #icon>
        <img src="@/assets/svgs/download.svg" alt="filter icon" class="" />
      </template>
    </Button>
    <Button
      style="padding-left: 22px !important; padding-right: 22px !important"
      class="global-button primary-button"
      label="Filtrar"
      @click="
        clearFilters(), (visible = true), (activeMode = 'filter'), getFunds()
      "
    >
      <template #icon>
        <img src="@/assets/svgs/filter.svg" alt="filter icon" class="mr-3" />
      </template>
    </Button>
  </div>
  <TableLoading v-if="loading" class="mt-5" />
  <div v-show="!loading && frameworks.length > 0">
    <EnquadramentosTable
      @framework-details="handleFrameworkdDetails"
      v-show="!loading && frameworks"
      :paginator-data="paginatorData"
      :data="frameworks"
      @pageEvent="getFundFrameworks"
    />
    <NoData v-if="!loading && frameworks.length == 0" />
  </div>
  <Dialog
    :draggable="false"
    v-model:visible="visible"
    modal
    :header="
      activeMode === 'process' ? 'Processar' : 'Filtrar' + ' enquadramento'
    "
    class="modal-dialog-frameworks"
  >
    <hr class="mb-5" />
    <div class="flex flex-col mt-4">
      <label for="name">
        {{ activeMode === "process" ? "Processar" : "Filtrar" }} por fundo
      </label>
      <Dropdown
        :editable="true"
        :loading="loadingFunds"
        placeholder="Buscar..."
        v-model="selectedFund"
        optionLabel="name"
        :options="funds"
        class="dropdown-custom"
      />
    </div>
    <div class="flex flex-col mt-4" v-if="activeMode === 'filter'">
      <label for="document"> CNPJ do fundo </label>
      <InputText class="modal-input" v-model="fundDocument" />
    </div>
    <div class="flex flex-col mt-4" v-if="activeMode === 'filter'">
      <label for="status"> Status </label>
      <Dropdown
        :editable="true"
        :loading="loadingFunds"
        placeholder="Status"
        v-model="selectedStatus"
        optionLabel="name"
        :options="status"
        class="dropdown-custom"
      />
    </div>
    <div class="flex flex-col mt-4">
      <label for="name"> Enquadrado?</label>
      <Dropdown
        :editable="true"
        placeholder="Enquadrado?"
        v-model="selectedIsCompliant"
        optionLabel="name"
        :options="isCompliantList"
        class="dropdown-custom"
      />
      {{ selectedIsCompliant }}
    </div>
    <!-- <div class="flex flex-col mt-4">
      <label for="name"> Data </label>
      <Calendar
        v-model="date"
        inline
        dateFormat="dd/mm/yy"
        :max-date="maxDate"
      />
    </div> -->
    <hr class="my-5" />
    <div class="flex xl:justify-end gap-2">
      <Button
        @click="clearFilters(), getFundFrameworks()"
        label="Limpar"
        class="rounded-lg text-white px-5 py-2 bg-neutral-4 hover:bg-primary-2"
        :disabled="loading"
      />
      <Button
        :loading="loadingProcessPost"
        :disabled="loading"
        :label="activeMode === 'process' ? 'Processar' : 'Filtrar'"
        class="rounded-lg text-white px-5 py-2 bg-primary hover:bg-secondary"
        @click="
          activeMode === 'process' ? processFund() : getFundFrameworks(),
            (visible = false)
        "
      />
    </div>
  </Dialog>
  <Dialog
    :draggable="false"
    v-model:visible="downloadDialogVisible"
    modal
    header="Exportar para Excel"
    class="modal-dialog-frameworks range-calendar"
    ><div class="flex flex-col mt-4">
      <label for="name"> Tipo de download:</label>
      <Dropdown
        :editable="true"
        placeholder="Tipo"
        v-model="selectedDownloadType"
        optionLabel="name"
        :options="downloadTypeList"
        class="dropdown-custom"
      />
    </div>
    <hr class="my-5" />
    <div class="flex xl:justify-end gap-2">
      <Button
        :loading="loadingXlsx"
        :disabled="loadingXlsx"
        :label="'Exportar'"
        class="rounded-lg text-white px-5 py-2 bg-primary hover:bg-secondary"
        @click="handleXlsxDownload"
      />
    </div>
  </Dialog>
</template>
<script setup lang="ts">
import Button from 'primevue/button';
import EnquadramentosTable from '@/components/enquadramentos/Table.vue';
import Dropdown from 'primevue/dropdown';
import Dialog from 'primevue/dialog';
import { onMounted, ref, watch } from 'vue';
import TableLoading from '@/components/loading/TableLoading.vue';
import { EnquadramentosService } from '@/services/enquadramentos/EnquadramentosService';
import { FundFramework } from '@/core/interfaces/Framework';
import NoData from '@/components/NoData.vue';
import { useRoute, useRouter } from 'vue-router';
import { FundService } from '@/services/funds/FundService';
import { Fund } from '@/core/interfaces/Funds';
import { useToast } from 'primevue/usetoast';
import { formatDate } from '../../common/formatDate';
import { debounce } from 'chart.js/helpers';
import InputText from 'primevue/inputtext';
const route = useRoute();
const loadingProcessPost = ref(false);
const toast = useToast();
const loadingFunds = ref(false);
const loading = ref(false);
const visible = ref(false);
const date = ref<Date>();
const selectedFund = ref();
const router = useRouter();
const downloadDialogVisible = ref(false);
const funds = ref<Fund[]>();
const frameworks = ref<FundFramework[]>([]);
const paginatorData = ref({});
const activeMode = ref('');
const loadingXlsx = ref(false);
const status = ref([
	{ name: '-', value: null },
	{ name: 'Em espera', value: 'waiting' },
	{ name: 'Processando', value: 'processing' },
	{ name: 'Finalizado', value: 'finished' },
	{ name: 'Falha', value: 'failed' },
]);
//watch vue
watch(selectedFund, (value) => {
	if (typeof value !== 'object') {
		getFunds(value);
	}
});
const selectedStatus = ref();
const isCompliantList = ref([
	{ name: '-', value: null },
	{ name: 'Sim', value: 'true' },
	{ name: 'Não', value: 'false' },
]);
const selectedIsCompliant = ref();
const fundDocument = ref<string>();
const selectedDownloadType = ref({ name: 'EXCEL', value: 'XLSX' });
const downloadTypeList = ref([{ name: 'EXCEL', value: 'XLSX' }]);
onMounted(() => {
	if (route.query.isCompliant) {
		selectedIsCompliant.value = isCompliantList.value.find(
			(option) => option.value === route.query.isCompliant
		);
	}

	if (route.params?.date && route.query.isCompliant) {
		selectedIsCompliant.value = isCompliantList.value.find(
			(option) => option.value === route.query.isCompliant
		);
	}
	if (route.params?.date && route.query.status) {
		selectedStatus.value = status.value.find(
			(option) => option.value === route.query.status
		);
	}
	getFundFrameworks();
});
const clearFilters = () => {
	selectedFund.value = null;
	date.value = undefined;
	selectedStatus.value = null;
	fundDocument.value = '';
	selectedIsCompliant.value = null;
};
const handleXlsxDownload = async () => {
	loadingXlsx.value = true;
	let filters: {
    fund?: number;
    status?: string;
    isCompliant?: boolean;
    'fund.document'?: string;
  } = {};
	if (selectedFund.value) {
		filters.fund = selectedFund.value.id;
	}
	if (fundDocument.value) {
		filters['fund.document'] = fundDocument.value;
	}
	if (selectedStatus.value) {
		filters.status = selectedStatus.value.value;
	}
	if (selectedIsCompliant.value) {
		filters.isCompliant = selectedIsCompliant.value.value;
	}
	const response = await EnquadramentosService.downloadXlsx(
    route.params.date as string,
    filters
	);
	if (response && !(response instanceof Error)) {
		//
		try {
			const file = response.data;
			const downloadLink = document.createElement('a');
			const fileName = 'enquadramento.xlsx';
			downloadLink.href = file;
			downloadLink.download = fileName;
			downloadLink.click();
			downloadDialogVisible.value = false;
		} catch (error) {
			toast.add({
				severity: 'error',
				summary: 'Erro ao baixar arquivo',
				detail: error,
				life: 5000,
			});
			loadingXlsx.value = false;
		} finally {
			loadingXlsx.value = false;
		}
	} else {
		toast.add({
			severity: 'error',
			summary: 'Erro ao baixar arquivo',
			detail: response.message,
			life: 5000,
		});
		loadingXlsx.value = false;
	}
};
const getFundFrameworks = async (pageData?: { page?: number }) => {
	loading.value = true;
	let filters: {
    page?: string;
    date?: string;
    fund?: number;
    status?: string;
    isCompliant?: boolean;
    'fund.document'?: string;
  } = {};
	if (pageData) {
		filters.page = pageData.page ? (pageData.page + 1).toString() : '1';
	}
	filters.date = route.params.date as string;
	if (selectedFund.value) {
		filters.fund = selectedFund.value.id;
	}
	if (fundDocument.value) {
		filters['fund.document'] = fundDocument.value;
	}
	if (selectedStatus.value) {
		filters.status = selectedStatus.value.value;
	}
	if (selectedIsCompliant.value) {
		filters.isCompliant = selectedIsCompliant.value.value;
	}
	const response = await EnquadramentosService.getFundFrameworks(filters);
	if (!(response instanceof Error) && response.data) {
		frameworks.value = response.data;
		paginatorData.value = response.meta;
	}
	loading.value = false;
};
const getFunds = debounce(async (searchValue?: string) => {
	loadingFunds.value = true;
	const response = await FundService.getFunds(undefined, searchValue || '');
	if (!(response instanceof Error) && response.data) {
		funds.value = response.data;
	}
	loadingFunds.value = false;
}, 700);
const processFund = async () => {
	loadingProcessPost.value = true;
	if (date.value) {
		const dateObject = new Date(date.value);
		const dateOnly = dateObject.toISOString().split('T')[0];
		const payload = {
			fundId: selectedFund?.value?.id as number,
			date: dateOnly,
		};
		const response = await EnquadramentosService.processFundFramework(payload);
		if (response) {
			toast.add({
				severity: 'success',
				summary: 'Enquadramento processado com sucesso!',
				detail: '',
				life: 5000,
			});
			visible.value = false;
			getFundFrameworks();
		} else {
			toast.add({
				severity: 'error',
				summary: 'Erro ao processar enquadramento',
				detail: '',
				life: 5000,
			});
		}
	}
	loadingProcessPost.value = false;
};
const handleFrameworkdDetails = (details: number) => {
	router.push({ name: 'enquadramentos-detalhes', params: { id: details } });
};
</script>
<style lang="scss" scoped>
input {
  border: solid 1px theme("colors.text.DEFAULT");
  padding: 0.2rem 1rem;
}
label {
  margin-bottom: 0.3rem;
  color: theme("colors.text.DEFAULT");
  font-weight: bold;
}
.dropdown-custom,
.modal-input {
  border: solid 1px theme("colors.text.DEFAULT");
  border-radius: 8px;
  position: relative !important;
  height: 41px;
  padding-left: 16px;
  .p-dropdown-trigger {
    background-color: red !important;
  }
}
</style>
<style>
.dropdown-custom {
  border: solid 1px theme("colors.text.DEFAULT");
  border-radius: 7px;
  position: relative !important;
  .p-dropdown-trigger {
    background-color: theme("colors.secondary.DEFAULT");
    color: theme("colors.white.DEFAULT");
  }
}
</style>
