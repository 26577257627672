<template>
  <h2 class="text-text font-semibold text-[24px]">
    {{ !checkIsEditMode ? "Criar" : "Editar" }} Tipo de ativo
  </h2>

  <form class="mt-10" @submit.prevent="handleFormSubmit">
    <div class="custom-grid create-fund-wrapper">
      <div class="flex flex-col font-semibold text-[18px] gap-1 input-wrapper">
        <label for="username">Tipo</label>
        <InputText required v-model="code" class="global-input" :loading="loading" disabled />
      </div>

      <div class="flex flex-col font-semibold text-[18px] gap-1 input-wrapper">
        <label for="username">Grupo</label>
        <InputText required v-model="group" class="global-input" :loading="loading" disabled />
      </div>
      <div class="flex flex-col font-semibold text-[18px] gap-1 input-wrapper">
        <label for="description">Descrição</label>
        <div class="card flex justify-content-center">
          <Dropdown v-model="selectedDescription" :options="descriptions" showClear optionLabel="name" placeholder="Selecione..."
            class="w-full md:w-14rem" />
        </div>

      </div>
      <div class="virtual-row" style="grid-column: 1/3"></div>
      <div class="flex flex-col font-semibold text-[18px] gap-1 input-wrapper" style="grid-column: 1">
        <label for="username">É direito creditório?</label>
        <InputSwitch :loading="loading" v-model="isCreditRight" />
      </div>
      <div class="flex flex-col font-semibold text-[18px] gap-1 input-wrapper mb-3" style="grid-column: 1">
        <label for="username">Revisado?</label>
        <InputSwitch :loading="loading" v-model="reviewed" />
      </div>
    </div>
    <hr class="mt-5" />
    <div class="flex justify-end gap-5">
      <Button label="Voltar" cl class="global-button secondary-button" @click="router.push({ name: 'asset-type' })" />
      <Button :loading="loading" :disabled="loading" type="submit" :label="'Salvar'"
        class="global-button primary-button" />
    </div>
  </form>
  <FirstPositionsTable title="Primeiras Posições" :data="fiveOlderPositions" :loading="loading">
  </FirstPositionsTable>
</template>

<script lang="ts" setup>
import InputText from "primevue/inputtext";
import InputSwitch from "primevue/inputswitch";
import { ref, onMounted, watch } from "vue";
import Button from "@/components/Button.vue";
import { useToast } from "primevue/usetoast";
import { useRoute, useRouter } from "vue-router";
import { AssetTypesService } from "@/services/asset-types/AssetTypesService";
import { FundPositionService } from "@/services/fund-positions/FundPositionsService";
import FirstPositionsTable from "@/components/asset-types/FirstPositionsTable.vue";
import { FundPositionByAssetType } from "@/core/interfaces/FundPositionByAssetType";
import Dropdown from "primevue/dropdown";


// ** Temporário

enum FinancialInstrumentEnum {
    CertificadosRecebiveisImobiliarios = "Certificados de Recebíveis Imobiliários",
    CessaoCreditoValoresVencidos = "Cessão de Crédito - Valores Vencidos",
    ContaCorrente = "Conta Corrente",
    CessaoCreditoEmpresasRecuperacaoJudicial = "Cessão de Crédito - Empresas em Recuperação Judicial",
    FundoCompensacaoVariacoesSalariais = "Fundo de Compensação de Variações Salariais",
    Debentures = "Debêntures",
    CertificadoDepositoAgropecuarioValoresVencidos = "Certificado de Depósito Agropecuário - Valores Vencidos",
    GarantiasRecuperadasImoveis = "Garantias Recuperadas - Imóveis",
    GarantiasRecuperadasVeiculos = "Garantias Recuperadas - Veículos",
    CertificadoDepositoAgropecuario = "Certificado de Depósito Agropecuário",
    AntecipacaoEspacoShowsEntretenimento = "Antecipação de Espaço de Shows e Entretenimento",
    LetraTesouroNacional = "Letra do Tesouro Nacional",
    CertificadoDepositoBancario = "Certificado de Depósito Bancário",
    LetraCreditoImobiliario = "Letra de Crédito Imobiliário",
    LetraFinanceiraValoresVencidos = "Letra Financeira - Valores Vencidos",
    LetraFinanceira = "Letra Financeira",
    CotaSubordinada = "Cota Subordinada",
    AtivosVencidos = "Ativos Vencidos",
    NotaPromissoria = "Nota Promissória",
    Acoes = "Ações",
    LetraFinanceiraTesouro = "Letra Financeira do Tesouro",
    DebenturesValoresVencidos = "Debêntures - Valores Vencidos",
    VendaPrazoValoresVencidos = "Venda a Prazo - Valores Vencidos",
    Fundo = "Fundo",
    NotaComercial = "Nota Comercial",
    ContratoFuturos = "Contrato Futuros",
    AcoesOpcoes = "Ações - Opções",
    VendaPrazo = "Venda a Prazo",
    OpcoesFlexiveisPut = "Opções Flexíveis - Put",
    CedulaProdutoRural = "Cédula de Produto Rural",
    CedulaCreditoImobiliario = "Cédula de Crédito Imobiliário",
    CompraDireitoShowsEntretenimentosVencidos = "Compra de Direito de Shows e Entretenimentos - Vencidos",
    CessaoCreditoAcaoJudicialVencido = "Cessão de Crédito - Ação Judicial - Vencido",
    NotaComercialValoresVencidos = "Nota Comercial - Valores Vencidos",
    DebenturesSimples = "Debêntures Simples",
    OperacoesSwap = "Operações SWAP",
    DepositoCaucao = "Depósito Caução",
    CertificadosRecebiveisAgronegocioValoresVencidos = "Certificados de Recebíveis do Agronegócio - Valores Vencidos",
    DireitosCreditoriosValoresVencidos = "Direitos Creditórios - Valores Vencidos",
    CompraPrazoValoresVencidos = "Compra a Prazo - Valores Vencidos",
    TitulosDividaEmpresasValoresVencidos = "Títulos de Dívida Emitido por Empresas - Valores Vencidos",
    CedulaCreditoBancario = "Cédula de Crédito Bancário",
    GarantiasRecuperadasMaquinas = "Garantias Recuperadas - Máquinas",
    Cambio = "Câmbio",
    CedulaCreditoBancarioValoresVencidos = "Cédula de Crédito Bancário - Valores Vencidos",
    NotaCreditoExportacao = "Nota de Crédito à Exportação",
    CotaSenior = "Cota Sênior",
    ContratoArrendamento = "Contrato de Arrendamento",
    CedulaCreditoImobiliarioValoresVencidos = "Cédula de Crédito Imobiliário - Valores Vencidos",
    Precatorios = "Precatórios",
    DireitosCreditorios = "Direitos Creditórios",
    ContratoMutuo = "Contrato de Mútuo",
    ProvisaoDevedoresDuvidosos = "Provisão Devedores Duvidosos",
    AntecipacaoEspacoShowsEntretenimentoValoresVencidos = "Antecipação de Espaço de Shows e Entretenimento - Valores Vencidos",
    CreditoCarbono = "Crédito Carbono",
    ContratoArrendamentoValoresVencidos = "Contrato de Arrendamento - Valores Vencidos",
    CertificadosRecebiveisAgronegocio = "Certificados de Recebíveis do Agronegócio",
    Outros = "Outros",
    CessaoCreditoExportacao = "Cessão de Crédito de Exportação",
    DireitosCreditoriosAPagar = "Direitos Creditórios a Pagar",
    NotaPromissoriaValoresVencidos = "Nota Promissória - Valores Vencidos",
    NotaTesouroNacional = "Nota do Tesouro Nacional",
    CessaoCreditoAcaoJudicial = "Cessão de Crédito - Ação Judicial",
    CertificadosRecebiveisImobiliariosValoresVencidos = "Certificados de Recebíveis Imobiliários - Valores Vencidos",
    AjusteCorrecaoValorPresente = "Ajuste Correção Valor Presente",
    CompraDireitoShowsEntretenimentosValoresVencidos = "Compra de Direito de Shows e Entretenimentos - Valores Vencidos",
    DireitoCreditorioSac = "Direito Creditório SAC",
    DebenturesConvertivel = "Debêntures Conversível",
    CompraPrazo = "Compra a Prazo",
    CessaoCredito = "Cessão de Crédito",
    CessaoCreditoNPL = "Cessão de Crédito - NPL",
    CedulaCreditoExportacaoValoresVencidos = "Cédula de Crédito de Exportação - Valores Vencidos",
    CessaoCreditoExportacaoValoresVencidos = "Cessão de Crédito de Exportação - Valores Vencidos",
    OpcoesFlexiveisCall = "Opções Flexíveis - Call",
    DepositoPrazoGarantiaEspecial = "Depósito a Prazo com Garantia Especial",
    CedulaProdutoRuralValoresVencidos = "Cédula de Produto Rural - Valores Vencidos",
    TitulosDividaEmpresas = "Títulos de Dívida Emitido por Empresas",
    ContratoConfissaoDivida = "Contrato de Confissão de Dívida",
    CedulaCreditoExportacao = "Cédula de Crédito de Exportação",
    CompraDireitoShowsEntretenimentos = "Compra de Direito de Shows e Entretenimentos",
    CotaMezanino = "Cota Mezanino",
    CertificadoDireitosCreditoriosAgronegocioValoresVencidos = "Certificado de Direitos Creditórios do Agronegócio - Valores Vencidos",
    RecebiveisValoresVencidos = "Recebíveis - Valores Vencidos",
    ContratoConfissaoDividaValoresVencidos = "Contrato de Confissão de Dívida - Valores Vencidos",
    DireitoCreditorioFromtis = "Direito Creditório Fromtis",
    CertificadoDireitosCreditoriosAgronegocio = "Certificado de Direitos Creditórios do Agronegócio"
}


const selectedDescription = ref();
const descriptions = ref(Object.entries(FinancialInstrumentEnum).map(([key, value]) => {
  return { code: key, name: value };
}));

const toast = useToast();

const router = useRouter();
const route = useRoute();

const code = ref("");
const group = ref("");
const isCreditRight = ref<boolean>(false);
const reviewed = ref<boolean>(true);
const fiveOlderPositions = ref<FundPositionByAssetType[]>([]);

const loading = ref(false);

onMounted(() => {
  checkIsEditMode();
});

const checkIsEditMode = () => {
  if (route.params.mode === "editar") {
    getAssetById();
    getFiveOlderPositions();
    return true;
  }
  return false;
};

const handleFormSubmit = async () => {
  loading.value = true;
  const payload = {
    code: code.value,
    group: group.value,
    isCreditRight: isCreditRight.value,
    reviewed: reviewed.value,
  };

  let response;

  if (!checkIsEditMode()) {
    response = await AssetTypesService.createAssetType(payload);
  } else {
    const id = route.params.id;
    response = await AssetTypesService.editAssetType(payload, id as string);
  }

  const createOrEditPast = !checkIsEditMode() ? "Criado" : "Editado";
  const createOrEditPresent = !checkIsEditMode() ? "Criar" : "Editar";

  if (!(response instanceof Error)) {
    toast.add({
      severity: "success",
      summary: `Tipo do ativo ${createOrEditPast} com sucesso!`,
      detail: "",
      life: 5000,
    });

    router.push({ name: "asset-type" });

    code.value = "";
    group.value = "";
    isCreditRight.value = false;
  } else {
    toast.add({
      severity: "error",
      summary: `Erro ao ${createOrEditPresent} tipo do ativo.`,
      detail: response,
      life: 5000,
    });
  }

  loading.value = false;
};

const getAssetById = async () => {
  const response = await AssetTypesService.getAssetTypeById(
    route.params.id as string
  );

  if (response && !(response instanceof Error)) {
    code.value = response.code;
    group.value = response.group;
    isCreditRight.value = response.isCreditRight;
  }
};

const getFiveOlderPositions = async () => {
  loading.value = true;

  const response = await FundPositionService.getOlderPositionsByAssetType(
    route.params.id as string
  );
  if (response && !(response instanceof Error)) {
    fiveOlderPositions.value = response;
  }
  if (response instanceof Error) {
    toast.add({
      severity: "error",
      summary: `Erro ao buscar as primeiras posições.`,
      detail: response,
      life: 5000,
    })
  }
  loading.value = false;
};
</script>

<style lang="scss">
// <style lang="scss" scoped>

// Dropdow Select
.p-focus.p-inputwrapper-focus {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

.create-fund-wrapper .p-dropdown {
  border: none !important;
}

.p-dropdown-label.p-inputtext.p-placeholder {
  border: 0.5px solid #33333342 !important;
  border-radius: 8px 0 0 8px !important;
}

.p-dropdown-trigger svg {
  color: #FDFDFD;
}

.p-dropdown .p-dropdown-clear-icon {
  right: 4em !important;
}



// Grid
.custom-grid {
  display: grid;
  grid-template-columns: 50% 50%;
  gap: 1.2rem;
  row-gap: 1.4rem;

  @media (max-width: 768px) {
    grid-template-columns: 100%;
  }
}
</style>
