export default function buildQueryString(
  path: string,
  filters?: { [key: string]: string | number }
): string {
  let queryString = path;

  if (filters && Object.keys(filters).length > 0) {
    let isFirstParam = true; // Variável para controlar se é o primeiro parâmetro

    queryString += "?";

    Object.entries(filters).forEach(([key, value]) => {
      if (value !== "" && value !== null && value !== undefined) {
        if (!isFirstParam) {
          queryString += "&";
        } else {
          isFirstParam = false;
        }

        if (key !== "page") {
          queryString += `filter.${key}=$eq:${encodeURIComponent(value)}`;
        } else {
          queryString += `${key}=${encodeURIComponent(value)}`;
        }
      }
    });
  }

  return queryString;
}
