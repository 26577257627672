import { onMounted } from 'vue';
<template>
  <h2 class="text-text font-semibold text-[24px]">Lista de Ativos</h2>
  <div class="mt-5 flex items-center gap-1 justify-end">
    <Button
      class="bg-secondary-2 text-[18px] hover:bg-secondary px-4 py-2 text-white border-solid border-transparent border-2"
      label="Filtrar"
      @click="clearFilters(), (visible = true)"
    >
      <template #icon>
        <img src="@/assets/svgs/filter.svg" alt="filter icon" class="mr-3 w-[16px]" />
      </template>
    </Button>
    <!-- <Button class="bg-secondary-4 border-2 border-solid border-secondary-5 xl:ml-2 hover:bg-secondary-1 hover:text-white px-5 py-3 text-secondary border-solid border-transparent border-2" label="Novo ativo"  @click="router.push({name: 'ativos-create-edit', params: {mode: 'criar'}})">
            <template #icon>
                <img src="@/assets/svgs/add.svg" alt="filter icon" class="mr-3">
            </template>
        </Button> -->
  </div>
  <TableLoading v-if="loading" class="mt-5" />

  <TableLoading v-if="loading" class="mt-5" />

  <div v-show="!loading && ativos.length > 0">
    <AtivosTable
      :paginator-data="paginatorData"
      :data="ativos"
      v-show="!loading && ativos && paginatorData"
      @pageEvent="getAtivos"
    />
  </div>

  <Dialog
    :draggable="false"
    v-model:visible="visible"
    modal
    header="Filtrar ativos"
    class="modal-dialog-frameworks"
  >
    <hr class="mb-5" />

    <div class="flex flex-col mt-4">
      <label for="codigo" class="modal-label"> Código </label>
      <InputText class=" dropdown-custom h-[39.98px] px-3"  v-model="code" />
    </div>

    <div class="flex flex-col mt-4">
      <label for="codigo" class="modal-label "> CNPJ Emissor </label>
      <InputText
        v-model="issuerDocument"
        v-maska
        data-maska="##.###.###/####-##"
        type="text"
       class="dropdown-custom h-[39.98px] px-3"
      />
    </div>

    <div class="flex flex-col mt-4">
      <label for="codigo" class="modal-label"> Tipo </label>
      <Dropdown
        filter
        placeholder="Tipo"
        v-model="selectedTypeCode"
        optionLabel="label"
        :options="typesCodesOptions"
        class="dropdown-custom"
      />
      <!-- <InputText v-model="selectedTypeCode" /> -->
    </div>

    <div class="flex flex-col mt-4">
      <label for="codigo" class="modal-label"> Grupo </label>
      <Dropdown
        filter
        placeholder="Grupo"
        v-model="selectedTypeGroup"
        optionLabel="label"
        :options="typesGroupsOptions"
        class="dropdown-custom"
      />
      <!-- <InputText v-model="selectedTypeCode" /> -->
    </div>

    <!-- <div class="flex flex-col mt-4">
      <label for="codigo"> Tipo </label>
      <Dropdown
        class="border-solid border-text border"
        :options="types"
        option-label="label"
        v-model="type"
        placeholder="Selecione o tipo"
      />
    </div> -->

    <hr class="my-5" />

    <div class="flex xl:justify-end gap-2">
      <Button
        @click="clearFilters(), getAtivos()"
        label="Limpar"
        class="rounded-lg text-secondary-2 px-5 py-2 bg-transparent border-primary-2 border-solid border-[1px] hover:bg-primary-2 hover:text-white"
      ></Button>
      <FilterButton
        label="Filtrar"
        class="bg-secondary-2 text-white hover:bg-secondary-2 hover:bg-primary "
        @click="getAtivos(), (visible = false)"
      />
    </div>
  </Dialog>
</template>

<script setup lang="ts">
import { Ativo } from '@/core/interfaces/Ativos';
import { AtivoService } from '@/services/ativos/AtivosService';
import { AssetTypesService } from '@/services/asset-types/AssetTypesService';
import { onMounted, ref } from 'vue';
import AtivosTable from '@/components/ativos/Table.vue';
import { MetaValues } from '@/core/interfaces/Customers';
import Button from 'primevue/button';
import FilterButton from '@/components/Button.vue';
const ativos = ref<Ativo[]>([]);
const paginatorData = ref({} as MetaValues);
const loading = ref(false);
import Dialog from 'primevue/dialog';
import InputText from 'primevue/inputtext';
import Dropdown from 'primevue/dropdown';
import TableLoading from '@/components/loading/TableLoading.vue';
import { vMaska } from 'maska';

const visible = ref(false);
const code = ref('');
const issuerDocument = ref('');
const selectedTypeCode = ref<{ value: string; label: string } | null>();
const typesCodesOptions = ref<{ value: string; label: string }[]>();
const selectedTypeGroup = ref<{ value: string; label: string } | null>();
const typesGroupsOptions = ref<{ value: string; label: string }[]>();

onMounted(() => {
	getAtivos();
	getAssetTypes();
});

const clearFilters = async () => {
	code.value = '';
	issuerDocument.value = '';
	selectedTypeCode.value = null;
	selectedTypeGroup.value = null;
};

const getAtivos = async (pageData?: { page?: number }) => {
	loading.value = true;
	const filters = {
		code: code.value,
		issuerDocument: issuerDocument.value,
		'type.code': selectedTypeCode.value?.value,
		'type.group': selectedTypeGroup.value?.value,
		page: typeof pageData?.page == 'number' ? pageData?.page + 1 : 1,
	};

	console.log('caiu get', pageData);	const response = await AtivoService.getAtivos(filters);

	if (response && !(response instanceof Error)) {
		ativos.value = response.data;
		paginatorData.value = response.meta;
	}
	loading.value = false;
};

const getAssetTypes = async () => {
	const response = await AssetTypesService.getAssetTypes({ limit: 9999 });

	if (response && !(response instanceof Error)) {
		typesCodesOptions.value = response.data.reduce(
			(pv: { value: string; label: string }[], cv) => {
				if (!pv.find((el: { value: string; label: string }) => cv.code == el.value)) {
					pv.push({ value: cv.code, label: cv.code });
				}
				return pv;
			},
			[]
		);
		typesGroupsOptions.value = response.data.reduce(
			(pv: { value: string; label: string }[], cv) => {
				if (!pv.find((el: { value: string; label: string }) => cv.group == el.value)) {
					pv.push({ value: cv.group, label: cv.group });
				}
				return pv;
			},
			[]
		);
	}
};
</script>


<style>
 
</style>