export function dateYYYYMMDD (date: Date) {

	let datePayload = date;

	if (!(datePayload instanceof Date)) {
		datePayload = new Date(datePayload);
	}
	
	if (date instanceof Date) {
		const ano = datePayload.getFullYear();
		const mes = String(datePayload.getMonth() + 1).padStart(2, '0');
		const dia = String(datePayload.getDate()).padStart(2, '0');
		return `${ano}-${mes}-${dia}`;
	} 
}
