<template>
  <Toast />
  <div class="login w-screen h-screen overflow-y-hidden,">
    <LoginForm @formSubmit="handleLogin" :loading="loading" />
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import LoginForm from '@/components/login/Form.vue';
import { LoginService } from '@/services/LoginService';
import Toast from 'primevue/toast';
import { useToast } from 'primevue/usetoast';
import { useRouter } from 'vue-router';

const toast = useToast();
const router = useRouter();

const loading = ref(false);

const handleLogin = async (formValues: { email: string; password: string }) => {
	loading.value = true;

	const response = await LoginService.login(formValues);
	if (response && !(response instanceof Error)) {
		const user = await LoginService.me(response.token);

		localStorage.setItem('token', response.token);
		localStorage.setItem('user', JSON.stringify(user));

		toast.add({
			severity: 'success',
			summary: 'Login efetuado com sucesso',
			detail: '',
			life: 3000,
		});
		router.push({ name: 'home' });
	} else {
		toast.add({
			severity: 'error',
			summary: 'Erro ao efetuar login.',
			detail: response.message,
			life: 3000,
		});
	}

	loading.value = false;
};
</script>

<style lang="scss">
.login {
  background: linear-gradient(67.15deg, theme("colors.secondary.2") 24.1%, theme("colors.secondary.3") 104.4%, theme("colors.secondary.2") 141.57%);

}
</style>
