import { AxiosError } from "axios";
import RequestService from "@/services/ApiService";
import { DashboardInterface } from "@/core/interfaces/Dashboard";

const requestService = new RequestService();

export class DashboarService {
  public static async getDashboardValues(): Promise<
    DashboardInterface | AxiosError
  > {
    const path = "/dashboard";

    try {
      const response = await requestService.get(path);

      return response as DashboardInterface;
    } catch (error: unknown) {
      return error as AxiosError;
    }
  }
}
