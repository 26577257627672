import { FundCprByCprType } from "@/core/interfaces/FundCprByCprType";
import RequestService from "../ApiService";
import { AxiosError } from "axios";

const requestService = new RequestService();

export class FundCprsService {
  public static async getOlderCprsByCprType(
    assetTypeId: number | string,
    limit = 5
  ) {
    const reqPath = `/fund-cprs/find-by-cprtype-asc/${assetTypeId}`;
    try {
      const config = {
        params: {
          limit: limit,
        },
      };
      const response = await requestService.get<[FundCprByCprType]>(
        reqPath,
        config
      );
      if (response && response.length) {
        const limitedData = response?.slice(0, 5);
        return limitedData;
      } else {
        return [];
      }
    } catch (error: unknown) {
      return error as AxiosError;
    }
  }
}
