<template>
  <div class="card-v2">
    <h2
      class="card-v2__title"
      :class="{ 'text-secondary-2': isUltimoEnquadramento }"
    >
      {{ title }}
    </h2>
    <div class="card-v2__wrapper">
      <span
        @click="pushToRoute"
        class="card-v2__value pl-2 cursor-pointer"
        :class="{ 'mx-auto pl-0': isUltimoEnquadramento }"
        >{{ props.value }}</span
      >
      <span
        v-if="props.showCircle"
        class="card-v2__circle"
        :style="{
          backgroundColor: props.backgroundColor,
          borderColor: props.borderColor,
          outlineColor: props.outlineColor,
        }"
      ></span>
    </div>
  </div>
</template>

<script setup lang="ts">
import { defineProps } from "vue";
import { useRouter } from "vue-router";

const router = useRouter();

const props = defineProps({
  title: {
    type: String,
    required: false,
    default: "",
  },
  backgroundColor: {
    type: String,
    default: 'theme("colors.secondary.2")',
  },
  borderColor: {
    type: String,
    default: 'theme("colors.white.DEFAULT")',
  },
  outlineColor: {
    type: String,
    default: "rgba(0, 146, 200, 0.3)",
  },
  showCircle: {
    type: Boolean,
    default: false,
  },
  value: {
    type: String,
    required: false,
    default: null,
  },
  isUltimoEnquadramento: {
    type: Boolean,
    default: false,
  },
});

const pushToRoute = () => {
  console.log("props.title", props.title);
  switch (props.title.toLowerCase()) {
    case "total de fundos":
      router.push({ name: "Fundos" });
      break;
    case "enquadramentos":
      router.push({ name: "enquadramentos" });

      break;

    default:
      break;
  }
};
</script>

<style lang="scss" scoped>
.card-v2 {
  background-color: theme("colors.white.DEFAULT");
  border-radius: 8px;
  border: solid 1px theme("colors.gray.200");
  padding: 12px;
  width: 227px;
  height: 90px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;

  &__wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: flex-end;
  }

  &__title {
    font-size: 15px;
    font-weight: 600;
    white-space: nowrap;
  }

  &__value {
    font-size: 25px;
    font-weight: bold;
    color: theme("colors.black");
    margin-top: 0.5rem;
    display: block;

    position: relative;
    /* &:before {
      content: "";
      position: absolute;
      width: calc(100% + 0.5rem);
      height: 1px;
      background-color: theme("colors.gray.200");
      bottom: 0px;
      left: 2px;
      right: 0;
      margin: auto;
      display: block;
    }*/
  }

  &__circle {
    width: 8.21px;
    height: 9px;
    border-radius: 50%;
    background-color: theme("colors.secondary.2");
    border: solid 1px theme("colors.white.DEFAULT");
    outline: solid 2px rgba($color: #0092c8, $alpha: 0.3);
  }
}
</style>
