import 'primevue/resources/themes/aura-light-green/theme.css';
import './style.css';
import './index.css';
import './assets/theme/main.scss';
import 'primevue/resources/themes/aura-light-green/theme.css';
import PrimeVue from 'primevue/config';
import ToastService from 'primevue/toastservice';
import { createApp } from 'vue';
import { install } from 'vue3-recaptcha-v2';
import App from './App.vue';
import router from './router';
import axios from '@/plugins/axios';
import Tooltip from 'primevue/tooltip';
const app = createApp(App);

app.directive('tooltip', Tooltip);


app.use(install, {
	sitekey: import.meta.env.VITE_APP_RECAPTCHA_SITE_KEY,
	cnDomains: false,
});
app
	.use(PrimeVue, {
		locale: {
			closeText: 'Fechar',
			prevText: 'Anterior',
			nextText: 'Próximo',
			currentText: 'Começo',
			monthNames: [
				'Janeiro',
				'Fevereiro',
				'Março',
				'Abril ',
				'Maio',
				'Junho',
				'Julho',
				'Agosto',
				'Setembro',
				'Outubro',
				'Novembro',
				'Dezembro',
			],
			monthNamesShort: [
				'Jan',
				'Fev',
				'Mar',
				'Abr',
				'Mai',
				'Jun',
				'Jul',
				'Ago',
				'Set',
				'Out',
				'Nov',
				'Dez',
			],
			dayNames: [
				'Domingo',
				'Segunda',
				'Terça',
				'Quarta',
				'Quinta',
				'Sexta',
				'Sábado',
			],
			dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'],
			dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
			weekHeader: 'Semana',
			firstDay: 1,
			isRTL: false,
			showMonthAfterYear: false,
			yearSuffix: '',
			timeOnlyTitle: 'Só Horas',
			timeText: 'Tempo',
			hourText: 'Hora',
			minuteText: 'Minuto',
			secondText: 'Segundo',
			ampm: false,
			month: 'Mês',
			week: 'Semana',
			day: 'Dia',
			allDayText: 'Todo Dia',
		},
	})
	.use(router)
	.use(axios, {
		baseUrl: import.meta.env.BASE_URL,
	})
	.use(ToastService)
	.mount('#app');
