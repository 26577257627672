<template>
  <div class="xl:flex xl:justify-between">
    <h2 class="text-text font-semibold text-xl">Detalhes do Enquadramento</h2>
    <div class="flex flex-col xl:flex-row gap-2">
      <Skeleton v-if="loading" class="xl:py-7 xl:px-10" />
      <Button
        v-else
        :label="isCompliant()"
        class="text-white text-xl font-bold uppercase"
        :class="getCompliantClasses()"
      />
      <span class="px-5 py-3 bg-neutral-8 rounded-lg text-primary font-medium"
        >Data do Enquadramento
        <span class="bg-white px-2 py-1 rounded-lg ml-2">{{
          formatDate(details?.date as string)
        }}</span></span
      >
    </div>
  </div>

  <Button
    v-if="!loading"
    label="Baixar carteira"
    class="mx-auto block bg-primary hover:bg-secondary-3 text-white mt-10 mb-5 xl:mb-0"
    @on-click="
      downloadByFundAndDate(
        details?.fund.id as any,
        details?.date.split(`T`)[0] as any
      )
    "
  />
  <div class="mt-10 custom-grid-1">
    <div>
      <Box title="Nome" :loading="loading">
        <template #content>
          <h2 class="text-primary font-medium">
            {{ details?.fund.name }}
          </h2>
        </template>
      </Box>
      <div class="flex flex-col xl:flex-row gap-8 mt-5">
        <Box title="Constituição">
          <template #content>
            <h2 class="text-primary font-medium">
              {{
                new Date(
                  details?.fund?.constitutionDate as any
                ).toLocaleDateString("pt-br")
              }}
            </h2>
          </template>
        </Box>
        <Box title="Primeira integralização" :loading="loading">
          <template #content>
            <h2 class="text-primary font-medium">
              {{
                new Date(
                  details?.fund.firstCapitalizationDate as any
                ).toLocaleDateString("pt-br")
              }}
            </h2>
          </template>
        </Box>
      </div>

      <h3 class="text-text font-medium mt-8 mb-3 text-xl">
        Composição da Carteira
      </h3>

      <span
        class="text-text mt-4 text-orange-500"
        v-if="!Array.isArray(details?.wallet?.portfolio)"
        >Nenhum ativo encontrado.</span
      >
      <DataTable
        :value="wallet"
        tableStyle="min-width: 50rem"
        class="clients-table"
        v-else
      >
        <Column field="label" header="Descrição do ativo" />
        <Column field="value" header="Valor Líquido">
          <template #body="slotProps">
            {{ formatCurrency(Number(slotProps.data.value)) }}
          </template>
        </Column>
        <Column field="" header="% do PL">
          <template #body="slotProps">
            {{
              (
                (slotProps.data.value / details.wallet.netWorth) *
                100
              ).toLocaleString("pt-BR", {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
              })
            }}%
          </template>
        </Column>
      </DataTable>

      <h3 class="text-text font-medium mt-8 mb-3 text-xl mt-5">
        Cálculo do Enquadramento
      </h3>
      <DataTable
        :value="[
          {
            label: 'Direitos Creditórios',
            value: details.wallet?.portfolioCreditRightsTotal,
          },
          {
            label: 'Direitos Creditórios (CPR)',
            value: details.wallet?.cprCreditRightsTotal,
          },
          {
            label: 'Direito Creditório por Equiparação',
            value: details?.rules?.sumOtherFidcsNetValue || 0,
          },
          {
            label: 'Renda Variável',
            value: details.wallet?.calcVariableIncomeNetValue,
          },
          {
            label: 'Cotas de FIA',
            value: details?.rules?.totalInvestedInFia || 0,
          },
          {
            label: 'Fundos Elegíveis',
            value: details?.rules?.eligibleFundsData?.totalNetValue ?? 0,
          },
        ]"
        tableStyle="min-width: 50rem"
        class="total-table"
        v-if="details?.wallet"
      >
        <Column field="label" header="">
          <template #body="slotProps">
            <b>{{ slotProps.data.label }}</b>
          </template>
        </Column>
        <Column field="netValue" header="Valor Líquido">
          <template #body="slotProps">
            {{ formatCurrency(Number(slotProps.data.value)) }}
          </template>
        </Column>
        <Column field="" header="% do PL">
          <template #body="slotProps">
            <span class="percentage-pill"
              >{{
                Number(
                  (slotProps.data.value / details.wallet.netWorth) * 100
                ).toLocaleString("pt-BR", {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                })
              }}%
            </span>
          </template>
        </Column>
      </DataTable>
    </div>
    <div>
      <div class="flex flex-col xl:flex-row gap-8 mb-5">
        <Box title="CNPJ" :loading="loading">
          <template #content>
            <input
              v-maska
              data-maska="##.###.###/####-##"
              class="table-input text-primary font-medium info-input"
              :value="details?.fund.document"
            />
          </template>
        </Box>
      </div>
      <div class="flex flex-col xl:flex-row gap-8 mb-5">
        <Box title="Entidade de Investimento?" :loading="loading">
          <template #content>
            <h2 class="text-primary font-medium">
              {{ fundInfo?.isInvestmentEntity ? "SIM" : "NÃO" }}
            </h2>
          </template>
        </Box>
      </div>
      <div class="flex flex-col xl:flex-row gap-8 mb-5">
        <Box title="Situação CVM">
          <template #content>
            <h2 class="text-primary font-medium">
              {{ fundInfo?.cvmSituation }}
            </h2>
          </template>
        </Box>
      </div>
      <div class="flex flex-col xl:flex-row gap-8">
        <Box title="Tipo CVM">
          <template #content>
            <h2 class="text-primary font-medium">
              {{ fundInfo?.cvmType }}
            </h2>
          </template>
        </Box>
        <Box title="Classe CVM">
          <template #content>
            <h2 class="text-primary font-medium">
              {{ fundInfo?.cvmClass }}
            </h2>
          </template>
        </Box>
      </div>
      <div>
        <Box title="Gestor" class="mt-5" :loading="loading">
          <template #content>
            <h2 class="text-primary font-medium" v-if="details?.fund.manager">
              {{ details?.fund.manager.name }}
            </h2>
          </template>
        </Box>
        <div
          class="border border-primary border-2 border-t-0 mt-5 rounded-lg pb-5"
        >
          <Box
            :title="'Patrimônio líquido Total'"
            :showCurrencyBox="true"
            :currencyValue="formatCurrency(Number(details?.wallet?.netWorth))"
            :showBoxBg="false"
            :loading="loading"
          >
          </Box>
          <div class="">
            <PieChart
              :chartData="chartData"
              :options="chartOptions"
              v-if="details?.wallet"
              style="aspect-ratio: 1"
            />
          </div>
        </div>
      </div>
    </div>
  </div>

  <Message
    v-if="details?.message"
    :message="details?.message"
    :status="details?.status"
    :isCompliant="details.isCompliant"
  />

  <!-- <br />
  <br />
  <br />
  <p>Solução temporária para a visualização da carteira.</p> -->

  <!-- <DataTable
    :value="positions"
    tableStyle="min-width: 50rem"
    v-if="positions && details?.wallet"
  >
    <Column field="asset_code" header="Ativo"> </Column>
    <Column field="asset_type_code" header="Tipo"> </Column>
    <Column field="asset_type_group" header="Grupo"> </Column>
    <Column field="asset_type_group" header="Grupo"> </Column>
    <Column field="netValue" header="Valor Líquido">
      <template #body="slotProps">
        {{ formatCurrency(Number(slotProps.data.netValue)) }}
      </template>
    </Column>
    <Column field="" header="% do PL">
      <template #body="slotProps">
        <span class="percentage-pill"
          >{{
            Number(
              (slotProps.data.netValue / details.wallet.netWorth) * 100
            ).toLocaleString("pt-BR", {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            })
          }}%
        </span>
      </template>
    </Column>
  </DataTable> -->

  <Button
    label="Voltar"
    class="mx-auto block bg-primary hover:bg-secondary-3 text-white mt-10 mb-5 xl:mb-0"
    @click="
      router.push({
        name: 'enquadramentos-lista',
        params: { date: details?.date.split('T')[0] },
      })
    "
  />
</template>

<script setup lang="ts">
import Box from "@/components/enquadramentos/Box.vue";
import Button from "@/components/Button.vue";
import { onMounted } from "vue";
import { EnquadramentosService } from "@/services/enquadramentos/EnquadramentosService";
import { useRoute } from "vue-router";
import { ref } from "vue";
import { FrameworkDetailsInterface } from "@/core/interfaces/FrameworkDetails";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import { formatCurrency } from "@/common/formatCurrency";
import { PieChart } from "vue-chart-3";
import { Chart, registerables } from "chart.js";
import { formatDate } from "../../common/formatDate";
import Skeleton from "primevue/skeleton";
import Message from "@/components/enquadramentos/Message.vue";
import { vMaska } from "maska";
import { FundService } from "@/services/funds/FundService";
import { FundPositionService } from "@/services/fund-positions/FundPositionsService";
import { FundInfo } from "@/core/interfaces/Funds";

import { useToast } from "primevue/usetoast";

import { useRouter } from "vue-router";
const router = useRouter();
const toast = useToast();
Chart.register(...registerables);

interface ChartData {
  labels: string[];
  datasets: {
    data: number[];
    backgroundColor?: string[];
    options?: Record<string, unknown>;
  }[];
}

const route = useRoute();
const details = ref<FrameworkDetailsInterface>();
const fundInfo = ref<FundInfo>();
const loading = ref(false);
const chartData = ref<ChartData>({
  labels: [""],
  datasets: [
    {
      data: [],
      // backgroundColor: null as any,
    },
  ],
});

const wallet = ref<WalletItem[]>([]);

const chartOptions = ref({
  plugins: {
    tooltip: {
      callbacks: {
        label: function (label: { raw: number }) {
          return formatCurrency(label.raw);
        },
      },
    },
    datalabels: {
      display: false,
    },
  },
});

onMounted(() => {
  getDetails();
});

const isCompliant = () => {
  if (details.value?.isCompliant) {
    return "Enquadrado";
  } else if (
    !details.value?.isCompliant &&
    details.value?.isCompliant !== null
  ) {
    return "Não enquadrado";
  } else if (details.value.status == "failed") {
    return "Falha";
  } else {
    return "Aguardando";
  }
};

const getCompliantClasses = () => {
  // ?'bg-frameworks-success' : 'bg-red-500'
  if (details.value?.isCompliant) {
    return "bg-frameworks-success";
  } else if (
    !details.value?.isCompliant &&
    details.value?.isCompliant !== null
  ) {
    return "bg-red-500";
  } else {
    return "bg-text";
  }
};

const getDetails = async () => {
  loading.value = true;
  const id = route.params.id;

  const response = await EnquadramentosService.getFundFrameworkdDetails(
    id as string
  );

  if (response && !(response instanceof Error)) {
    if (response.wallet) {
      response.wallet.portfolio = [
        {
          assetGroup: "CPR",
          assetType: "CPR",
          fundDocument: "",
          netValue: response.wallet?.cprTotal,
        },
        ...(response.wallet?.portfolio || []),
      ];
    }

    details.value = response;
    getFundInfo(response.fund.document, response.date.split("T")[0]);

    getFundWallet(response.fund?.id, response.date.split("T")[0]);
  }

  loading.value = false;
};

const getFundWallet = async (fundId: number, date: string) => {
  const response = await FundService.getFundWalletOnDate(fundId, date);
  wallet.value = (response as WalletItem[]).filter(
    (el: WalletItem) => el.value >= 0.01 || el.value <= -0.01
  );
  handleChartData(wallet.value);
};

const downloadByFundAndDate = async (fundId: number, date: string) => {
  try {
    const response = await FundPositionService.downloadByFundAndDate(
      fundId,
      date
    );
    const file = response.data;

    const downloadLink = document.createElement("a");
    const fileName = `carteira-${fundId}-${date}.xlsx`;

    downloadLink.href = file;
    downloadLink.download = fileName;
    downloadLink.click();
  } catch (error) {
    toast.add({
      severity: "error",
      summary: "Erro ao baixar arquivo",
      detail: error,
      life: 5000,
    });
  }
};

const getFundInfo = async (fundDocument: string, date: string) => {
  const response = await FundService.getFundInfoOnDate(fundDocument, date);
  if (response && !(response instanceof Error)) {
    fundInfo.value = response;
  }

  loading.value = false;
};

interface WalletItem {
  label: string;
  value: number;
}

const handleChartData = (wallet: WalletItem[]) => {
  const data = wallet;
  const values: number[] = [];

  if (data) {
    data?.forEach((i: WalletItem) => {
      values.push(Number(i.value));
    });

    chartData.value.labels = data.map((i: WalletItem) => i.label || "");

    chartData.value.datasets[0].data = values;

    // const netValuesSum = data.portfolio.reduce((acc, curr) => {
    //   return acc + curr.netValue;
    // }, 0);

    // // const others = data.netWorth - netValuesSum;

    // // chartData.value.labels.push("Outros");
    // chartData.value.datasets[0].data.push(others);
  }
};
</script>

<style lang="scss" scoped>
.custom-grid-1 {
  display: grid;
  grid-template-columns: 60% 40%;
  padding: 0 2rem;
  gap: 2rem;
  @media (max-width: 1510px) {
    grid-template-columns: repeat(2, 50%);
  }
  @media screen and (max-width: 1024px) {
    grid-template-columns: 100%;
    padding: 0;
  }
}

#pie-chart {
  @media screen and (max-width: 1024px) {
    width: 300px;
    margin-top: 1rem;
  }
}

.info-input {
  display: block;
  color: theme("colors.primary.DEFAULT");
  padding: 0;
}
</style>

<style lang="scss">
.total-table {
  tr th {
    color: theme("colors.primary.DEFAULT") !important;
    font-weight: bold !important;
    background-color: #d9d9d9 !important;
  }
  .percentage-pill {
    background-color: theme("colors.secondary.DEFAULT") !important;
    color: theme("colors.white.DEFAULT") !important;
    width: 80px;
    border-radius: 4px;
    display: block;
    margin: 0 auto;
  }
}
</style>
