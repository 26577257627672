
import RequestService from '@/services/ApiService';
import { AxiosError } from 'axios';
import { ManagerResponse } from '@/core/interfaces/Managers';

const requestService = new RequestService();

export class ManagersService {

	public static async getManagers (searchValue?: string): Promise<ManagerResponse | AxiosError> {
		let path = '/managers';
		searchValue ? path += `?search=${searchValue}` : path;
		
		try {
			const response : ManagerResponse = await requestService.get(path);      

	
			return response;
		} catch (error: unknown) {
			return error as AxiosError;
		}
	}
	
    
}

	

	

