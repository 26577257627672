import { AxiosError } from 'axios';
import RequestService from '@/services/ApiService';



const requestService = new RequestService();

export class CaptchaService {
	
	public static async captchaValidation(token: string): Promise<boolean | Error> {
		try {
			const response = await requestService.post<boolean>('/captcha/validate', {
				token: token,
			});

			return response;
		} catch (error: unknown) {
			return error as AxiosError;
		}
	}

	
}
