import { AxiosError } from 'axios';
import RequestService from '@/services/ApiService';
import { FundInfo, FundsInterface } from '@/core/interfaces/Funds';

import { FundCreate } from '@/core/interfaces/FundCreate';

const requestService = new RequestService();

export class FundService {
	public static async getFunds(
		filters?: {
      page?: number;
      cnpj: string;
      isEntityName: boolean;
      manager: number;
      name: string;
      type: string;
      cvmClass: string;
    },
		searchValue?: string
	): Promise<FundsInterface | AxiosError> {
		let path =
      '/funds?filter.cvmSituation=$in:EM FUNCIONAMENTO NORMAL,EM SITUAÇÃO ESPECIAL,INCORPORAÇÃO,LIQUIDAÇÃO';

		if (filters?.cnpj) {
			path += `?filter.document=${filters.cnpj}`;
		}
		if (filters?.name) {
			path += `?search=${filters.name}`;
		}

		if (filters?.isEntityName) {
			path += `?filter.investmentEntity=${filters.isEntityName}`;
		}

		if (filters?.manager) {
			path += `?filter.manager=${filters.manager}`;
		}

		if (filters?.type) {
			path += `?filter.type=${filters.type}`;
		}

		if (filters?.cvmClass) {
			path += `?filter.classCvm=${filters.cvmClass}`;
		}

		if (filters?.page) {
			path += `?page=${filters.page}`;
		}

		if (searchValue) {
			path += `?search=${searchValue}`;
		}

		const isFirstParam = path.indexOf('?');

		if (path.indexOf('?', isFirstParam + 1) !== -1) {
			path =
        path.slice(0, isFirstParam + 1) +
        path.slice(isFirstParam + 1).replace(/\?/g, '&');
		}

		try {
			console.log(`Path: ${path}`);
			const response = await requestService.get(path);

			return response as FundsInterface;
		} catch (error: unknown) {
			return error as AxiosError;
		}
	}

	public static async getFundInfoOnDate(
		fundDocument: string,
		date: string
	): Promise<FundInfo | AxiosError> {
		const path = '/all-funds-infos';

		const response = await requestService.get(
			`${path}/${fundDocument}/${date}`
		);

		return response as FundInfo;
	}

	public static async getFundWalletOnDate(
		fundId: number,
		date: string
	): Promise<unknown | AxiosError> {
		const path = `/funds/${fundId}/wallet/${date}`;

		const response = await requestService.get(path);

		return response as FundInfo;
	}

	public static async createFund(
		payload: FundCreate
	): Promise<FundCreate | AxiosError> {
		const path = '/funds';

		try {
			const response = await requestService.post(path, payload);

			return response as FundCreate;
		} catch (error: unknown) {
			return error as AxiosError;
		}
	}

	public static async editFund(
		payload: FundCreate,
		id: string
	): Promise<FundCreate | AxiosError> {
		const path = `/funds/${id}`;

		try {
			const response = await requestService.put(path, payload);
			return response as FundCreate;
		} catch (error: unknown) {
			return error as AxiosError;
		}
	}

	public static async getFundById(
		id: string
	): Promise<FundCreate | AxiosError> {
		const path = `/funds/${id}`;

		try {
			const response = await requestService.get(path);

			return response as FundCreate;
		} catch (error: unknown) {
			return error as AxiosError;
		}
	}

	public static async getFundsTypes(): Promise<string[] | AxiosError> {
		const path = '/funds/types';

		try {
			const response = await requestService.get(path);

			return response as string[];
		} catch (error: unknown) {
			return error as AxiosError;
		}
	}

	public static async getCvmClasses(): Promise<string[] | AxiosError> {
		const path = '/funds/cvm-classes';

		try {
			const response = await requestService.get(path);

			return response as string[];
		} catch (error: unknown) {
			return error as AxiosError;
		}
	}
}
