import { AxiosError } from 'axios';
import AuthRequestService from '@/services/AuthRequestService';
import { UserLogin } from '@/core/interfaces/login';
import { PasswordRecoveryResponse } from '@/core/interfaces/login';
import { User } from '@/core/interfaces/User';

const requestService = new AuthRequestService();

export class LoginService {
	public static async login(
		formValues: UserLogin
	): Promise<{ token: string } | Error> {
		try {
			const response = await requestService.post<{ token: string }>(
				'/auth/signin',
				{
					email: formValues.email,
					password: formValues.password,
				}
			);

			return response;
		} catch (error: unknown) {
			return error as AxiosError;
		}
	}



	public static async me(token: string): Promise<User | Error> {
		try {
			const response = await requestService.get<User>('/auth/me', {
				headers: { Authorization: 'Bearer ' + token },
			});

			return response;
		} catch (error: unknown) {
			return error as AxiosError;
		}
	}

	public static async passwordrecovery(
		email: string
	): Promise<PasswordRecoveryResponse | Error> {
		try {
			const response = await requestService.post<PasswordRecoveryResponse>(
				'/auth/send-recover-email',
				{
					email: email,
				}
			);

			return response;
		} catch (error: unknown) {
			return error as AxiosError;
		}
	}
}
