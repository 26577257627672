<template>
  <form @submit.prevent="handleFormSubmit">
    <div class="custom-grid create-fund-wrapper">
      <div class="flex flex-col font-semibold text-[18px] gap-1 input-wrapper">
        <label for="username">Gestor</label>
        <!-- <Dropdown
          :loading="loadingManagers"
          :disabled="checkIsEditMode() ? true : false"
          placeholder="Buscar..."
          v-model="manager"
          optionLabel="name"
          :options="managers"
          class="bg-calendar-1 py-1 px-3"
        /> -->
        <InputText
          :disabled="checkIsEditMode() ? true : false"
          :loading="loading"
          required
          v-model="manager"
          class="w-full border-none px-3 py-2"
        />
      </div>
      <div class="flex flex-col font-semibold text-[18px] gap-1 input-wrapper">
        <label for="username">Nome</label>
        <IconField iconPosition="right">
          <InputIcon
            class="pi pi-lock"
            v-if="checkIsEditMode() ? true : false"
          ></InputIcon>
          <InputText
            required
            v-model="name"
            class="w-full border-none px-3 py-2"
            :loading="loading"
            :disabled="checkIsEditMode() ? true : false"
          />
        </IconField>
      </div>

      <div class="flex flex-col font-semibold text-[18px] gap-1 input-wrapper">
        <label for="username">Tipo CVM</label>
        <InputText
          :disabled="checkIsEditMode() ? true : false"
          :loading="loading"
          required
          v-model="type"
          class="w-full border-none px-3 py-2"
        />
      </div>

      <div class="flex flex-col font-semibold text-[18px] gap-1 input-wrapper">
        <label for="username">Classe CVM</label>
        <InputText
          :disabled="checkIsEditMode() ? true : false"
          :loading="loading"
          required
          v-model="classCvm"
          class="w-full border-none px-3 py-2"
        />
      </div>

      <div class="flex flex-col font-semibold text-[18px] gap-1 input-wrapper">
        <label for="username">CNPJ</label>
        <IconField iconPosition="right">
          <InputIcon
            class="pi pi-lock"
            v-if="checkIsEditMode() ? true : false"
          ></InputIcon>
          <InputText
            :disabled="checkIsEditMode() ? true : false"
            :loading="loading"
            v-maska
            data-maska="##.###.###/####-##"
            required
            v-model="document"
            class="w-full border-none px-3 py-2"
          />
        </IconField>
      </div>

      <!-- <div class="flex flex-col font-semibold text-[18px] gap-1 input-wrapper">
        <label for="username">Código</label>
        <InputText
          :disabled="checkIsEditMode() ? true : false"
          :loading="loading"
          required
          v-model="code"
          class="w-full border-none px-3 py-2"
        />
      </div> -->
      <div class="flex flex-col font-semibold text-[18px] gap-1 input-wrapper">
        <label for="username">Data de Início</label>
        <Calendar
          :disabled="checkIsEditMode() ? true : false"
          :loading="loading"
          required
          v-model="startDate"
          dateFormat="dd/mm/yy"
          :max-date="new Date()"
        />
      </div>
      <div class="flex flex-col font-semibold text-[18px] gap-1 input-wrapper">
        <label for="username">Data da Primeira Cotização</label>
        <Calendar
          :disabled="checkIsEditMode() ? true : false"
          :loading="loading"
          required
          v-model="firstCapitalizationDate"
          dateFormat="dd/mm/yy"
          :max-date="new Date()"
        />
      </div>
      <!-- <div class="flex flex-col justify-center font-semibold text-[18px] gap-1">
        <label for="username">É Entidade de Investimento? *</label>
        <InputSwitch :loading="loading" required v-model="isInvestmentEntity" />
      </div> -->
    </div>
    <hr class="mt-5" />
    <div class="flex justify-end gap-5 mb-5 xl:mb-0">
      <Button
        label="Voltar"
        class="bg-neutral-4 hover:bg-secondary-2 text-white mt-5 px-10"
        @click="router.push({ name: 'Fundos' })"
      />
      <Button
        disabled
        :loading="loadingPost"
        type="submit"
        :label="'Salvar'"
        class="bg-primary hover:bg-secondary-2 text-white mt-5 px-10"
      />
    </div>
  </form>
</template>

<script lang="ts" setup>
import InputText from "primevue/inputtext";
import Calendar from "primevue/calendar";
import IconField from "primevue/iconfield";
import InputIcon from "primevue/inputicon";
import { ref, onMounted } from "vue";
import Button from "@/components/Button.vue";
import { Manager } from "@/core/interfaces/Managers";
import { ManagersService } from "@/services/managers/ManagersService";
import { vMaska } from "maska";
import { dateYYYYMMDD } from "@/common/dateToYYYYMMDD";
import { FundService } from "@/services/funds/FundService";

import { useToast } from "primevue/usetoast";
import { useRoute, useRouter } from "vue-router";
import { formatDate } from "@/common/formatDate";
import { FundCreate } from "@/core/interfaces/FundCreate";
import { AxiosError } from "axios";

const router = useRouter();
const route = useRoute();
const toast = useToast();
const manager = ref();
const managers = ref<Manager[]>([]);
const loadingManagers = ref(false);
const name = ref("");
const type = ref();
const classCvm = ref("");
const document = ref("");
const startDate = ref();
const firstCapitalizationDate = ref();
const isInvestorFund = ref(false);
const isInvestmentEntity = ref();
const code = ref("");
const loadingPost = ref(false);
const loading = ref(false);

onMounted(() => {
  getManagers();
  checkIsEditMode();

  if (route.params.mode === "editar") {
    getFundById();
  }
});

const checkIsEditMode = () => {
  if (route.params.mode === "editar") {
    return true;
  }
  return false;
};

const getFundById = async () => {
  loading.value = true;
  const response: FundCreate | AxiosError = await FundService.getFundById(
    route.params.id as string
  );

  if (!(response instanceof Error)) {
    code.value = response.code;
    name.value = response.name;
    document.value = response.document;
    firstCapitalizationDate.value = formatDate(
      response.firstCapitalizationDate
    );
    isInvestmentEntity.value = response.isInvestmentEntity;
    isInvestorFund.value = response.isInvestorFund;
    type.value = response.type;
    classCvm.value = response.classCvm;
    startDate.value = formatDate(response.startDate);
    manager.value =
      typeof response.manager != "number" ? response.manager.name : "";
  }

  loading.value = false;
};

const getManagers = async () => {
  loadingManagers.value = true;

  const response = await ManagersService.getManagers();

  if (response && !(response instanceof Error)) {
    managers.value = response.data;
  }

  loadingManagers.value = false;
};

const handleFormSubmit = async () => {
  loadingPost.value = true;
  const payload: FundCreate = {
    isInvestorFund: isInvestorFund.value,
    manager: Number(manager?.value?.id),
    name: name.value,
    document: document.value,
    type: type.value?.value ?? "",
    code: code.value,
    isInvestmentEntity: isInvestmentEntity.value,
    startDate: dateYYYYMMDD(startDate?.value) as string,
    firstCapitalizationDate: dateYYYYMMDD(
      firstCapitalizationDate.value
    ) as string,
    classCvm: classCvm.value,
  };

  let response;

  if (!checkIsEditMode) {
    response = await FundService.createFund(payload);
  } else {
    const fundId = route.params.id;
    response = await FundService.editFund(payload, fundId as string);
  }

  const createOrEditPast = !checkIsEditMode ? "Criado" : "Editado";
  const createOrEditPresent = !checkIsEditMode ? "Criar" : "Editar";

  if (!(response instanceof Error)) {
    toast.add({
      severity: "success",
      summary: `Fundo ${createOrEditPast} com sucesso!`,
      detail: "",
      life: 5000,
    });

    router.push({ name: "Fundos" });

    manager.value.id = null;
    name.value = "";
    document.value = "";
    type.value = undefined;
    code.value = "";
    isInvestmentEntity.value = "";
    startDate.value;
    firstCapitalizationDate.value = "";
  } else {
    toast.add({
      severity: "error",
      summary: `Erro ao ${createOrEditPresent} fundo.`,
      detail: response,
      life: 5000,
    });
  }

  loadingPost.value = false;
};
</script>

<style lang="scss" scoped>
.custom-grid {
  display: grid;
  grid-template-columns: 50% 50%;
  gap: 1.2rem;
  @media screen and (max-width: 768px) {
    grid-template-columns: 100%;
    padding: 1rem 0.5rem;
  }
}
</style>
