<template>
    <form @submit.prevent="handleFormSubmit" class="flex justify-center items-center w-screen h-screen">
        <div class="bg-primary rounded-lg py-12 flex flex-col items-center justify-center xl:min-w-[731px] xl:min-h-[518px]">
            <img src="@/assets/svgs/envelop.svg"/>
            <span class="block text-center text-white font-semibold text-[24px]">Redefinição de Senha</span>
            <p class="text-white text-lg text-center font-light mt-5 leading-6">Para prosseguir com a redefinição de senha <br/>
                digite no campo abaixo o seu e-mail</p>

                <div class="flex flex-col gap-2 xl:min-w-[400px]">
                    <label class="text-white mb-0 mt-10" for="username">Username</label>
                    <InputText required type="email" class="rounded-lg py-3 px-3" id="username" v-model="email" aria-describedby="username-help" placeholder="email@exemplo.com" />
                    <Button :loading="loading" type="submit" label="Redefinir Senha" class="bg-secondary hover:bg-primary-2 uppercase text-white py-3 mt-2 px-3"/>
                </div>
                <RouterLink class="mt-10 text-white  underline" :to="{name: 'login'}"> Voltar </RouterLink>
        </div>
        
    </form>
</template>


<script setup lang="ts">
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import { ref } from 'vue';
import { LoginService } from '@/services/LoginService';
import { useToast } from 'primevue/usetoast';
import isValidResponse from '@/common/functions.common';
import { useRouter } from 'vue-router';

const router = useRouter();
const email = ref('');
const loading = ref(false);
const toast = useToast();

const handleFormSubmit = async () => {
	loading.value = true;
	const response = await LoginService.passwordrecovery(email.value);

	if (isValidResponse(response)) {
		toast.add({
			severity: 'success',
			summary: 'Requisição feita com sucesso!',
			detail: response.message,
			life: 8000
		});
		router.push({name: 'login'});
	} else {
		toast.add({
			severity: 'error',
			summary: 'Erro ao efetuar login.',
			detail: response.message,
			life: 3000
		});
	}

	loading.value = false;
};

</script>