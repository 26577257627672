import { AxiosError } from "axios";
import RequestService from "@/services/ApiService";
import { CprType, CprTypesInterface } from "@/core/interfaces/CprTypes";

const requestService = new RequestService();

export class CprTypesService {
  public static async getCprTypes(filters?: {
    code?: string;
    isCreditRight?: string;
    reviewed?: string;
    description?: string;
    page?: number;
  }): Promise<CprTypesInterface | AxiosError> {
    let path = "/cpr-types";

    let params: any = filters;
    if (filters?.code) {
      params["search"] = filters.code;
    }

    if (filters?.reviewed) {
      params["filter.reviewed"] = `$eq:${filters.reviewed}`;
    }

    if (filters?.description) {
      params["filter.description"] = `$ilike:${filters.description}`;
    }

    try {
      const response = await requestService.get(path, { params });

      return response as CprTypesInterface;
    } catch (error: unknown) {
      return error as AxiosError;
    }
  }

  public static async createCprType(
    payload: CprType
  ): Promise<CprType | AxiosError> {
    let path = "/cpr-types";

    try {
      const response = await requestService.post(path, payload);
      
      return response as CprType;
    } catch (error: unknown) {
      return error as AxiosError;
    }
  }

  public static async editCprType(
    payload: CprType,
    id: string
  ): Promise<CprType | AxiosError> {
    const path = `/cpr-types/${id}`;

    try {
      const response = await requestService.put(path, payload);

      return response as CprType;
    } catch (error: unknown) {
      return error as AxiosError;
    }
  }

  public static async getCprTypeById(
    id: string
  ): Promise<CprType | AxiosError> {
    const path = `/cpr-types/${id}`;

    try {
      const response = await requestService.get(path);

      return response as CprType;
    } catch (error: unknown) {
      return error as AxiosError;
    }
  }
}
