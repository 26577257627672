/* eslint-disable @typescript-eslint/no-explicit-any */
// debounce.ts
const debounce = <T extends (...args: any[]) => any>(fn: T, delay: number) => {
	let timeout: NodeJS.Timeout | null = null;
  
	return (...args: Parameters<T>) => {
		if (timeout) {
			clearTimeout(timeout);
		}
  
		timeout = setTimeout(() => {
			fn(...args);
		}, delay);
	};
};
  
export default debounce;
  