/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';
import type {
	AxiosInstance,
	AxiosResponse,	
	AxiosRequestConfig,
} from 'axios';


class RequestService {
	private axiosInstance: AxiosInstance;

	constructor () {
		this.axiosInstance = axios.create( {
			baseURL: import.meta.env.VITE_APP_API_URL,
		} );
		this.axiosInstance.interceptors.request.use( ( config ) => {
			const token = localStorage.getItem( 'token' );
			if ( token ) {
				config.headers.Authorization = `Bearer ${token}`;
			}
			return config;
		} );
	}

	public async get<T> ( url: string, config?: AxiosRequestConfig ): Promise<T> {
		try {

			const response: AxiosResponse<T> = await this.axiosInstance.get(
				url,
				config
			);
			return response.data;
		} catch ( error: unknown ) {
			throw new Error( `Failed to make GET request to ${url}.` );
		}
	}

	public async post<T> ( url: string, data?: any ): Promise<T> {
		try {
			const response: AxiosResponse<T> = await this.axiosInstance.post(
				url,
				data
			);
			return response.data;
		} catch ( error: any ) {
			throw new Error( `${error.response.data.message}` );
			return error.response.data.message;
		}
	}

	public async delete<T> ( url: string ): Promise<any> {
		try {
			const response: AxiosResponse<any> = await this.axiosInstance.delete( url );
			return response;
		} catch ( error: any ) {
			throw new Error( `${error.response.data.message}` );
			return error.response.data.message;
		}
	}

	public async put<T> ( url: string, data: unknown ): Promise<any> {
		try {
			const response: AxiosResponse<any> = await this.axiosInstance.put(
				url,
				data
			);
			return response;
		} catch ( error: any ) {
			throw new Error( `${error.response.data.message}` );
			return error.response.data.message;
		}
	}

	// Adicione outros métodos de requisição conforme necessário
}

export default RequestService;
