<template>
<Skeleton v-if="loading" class="my-5 py-5 bg-primary"/>

<div v-else class="inline-flex flex-col w-full">
	
    <div class="rounded-tl-lg rounded-tr-lg bg-primary px-4 py-2 inline-block" >
        <h2 class="text-neutral-6 font-bold">{{ title }}
            
            <span v-if="showCurrencyBox && !loading" class="bg-primary-1 px-3 py-1 text-white uppercase rounded-lg xl:ml-3 block xl:inline-block mt-1 xl:mt-0">		
				{{  getValue() }}
             
            </span>
    </h2>        
    </div>
    <div class="bg-neutral-7 rounded-bl-lg rounded-br-lg" :class="showBoxBg ? 'px-4 py-3' : ''">
        <slot name="content"  v-if="!loading"></slot>
    </div>
</div>
</template>

<script setup lang="ts">

import Skeleton from 'primevue/skeleton';

const getValue = () => {
	
	if (props.currencyValue.includes('NaN,undefined')) {
		return '--';
	}
	return props.currencyValue;
};


const props = defineProps({
	title: {
		type: String,
		required: false,
		default: ''
	},
	showCurrencyBox: {
		type: Boolean,
		required: false,
		default: false
	},
	currencyValue: {
		type: String,
		required: false,
		default: ''
	},
	loading: {
		type: Boolean,
		required:false, 
		default: false
	},
	showBoxBg: {
		type: Boolean,
		required:false, 
		default: true
	}
});
</script>