<template>
  <div class="mobile-menu" :class="['col-span-6']">
    <div class="menu-icon" @click="toggleMenu()">
      <span :class="{ 'menu-icon-active': menu.isOpen }"></span>
      <span></span>
      <span :class="{ 'menu-icon-active': menu.isOpen }"></span>
    </div>
    <div
      class="text-white font-black text-center self-center ml-auto mr-auto mx-0 px-0 relative right-4"
    >
      <Logo />
    </div>
  </div>
  <nav
    class="navbar-custom transition-all relative z-50"
    :class="[
      'navbar-custom',
      { 'navbar-active': menu.isOpen },
      showRouteNames ? 'w-[237px]' : 'w-[63px]',
    ]"
  >
    <div class="sticky top-0 h-screen">
      <div :class="showRouteNames ? 'grid grid-cols-11' : 'w-full'">
        <div
          :class="showRouteNames ? 'col-span-2 grid-spacer' : 'hidden'"
        ></div>
        <div
          :class="
            showRouteNames
              ? 'logo-container col-span-7 flex justify-center items-center py-[45px]'
              : 'hidden'
          "
        >
          <Logo />
        </div>
        <button
          :class="
            showRouteNames
              ? 'navbar-close py-9 px-5 rounded-tl-lg rounded-bl-lg mb-10 col-span-2'
              : 'no-shadow pb-[67px] pt-[50px] w-full'
          "
          @click="toggleMenuVisibility"
          :style="
            showRouteNames
              ? 'background-color: #F0F1F3!important;'
              : 'background-color:#E1E0E1!important;'
          "
        >
          <img
            src="../../assets/svgs/x-1.svg"
            alt="close navbar button"
            style="width: 13px; max-width: 13px"
            v-if="showRouteNames"
          />
          <img
            src="../../assets/svgs/hamburguer-new.svg"
            alt="close navbar button"
            class="m-auto"
            v-else
          />
        </button>
      </div>

      <ul
        :class="
          showRouteNames
            ? 'flex flex-col gap-3 items-center text-[16px] mb-5 cursor-pointer w-full px-10 py-2 transition-all'
            : ''
        "
        @click="toggleMenu"
      >
        <li
          v-for="topLevelMenuItem in menu.children"
          :key="topLevelMenuItem.id"
          :id="`MENU-ID-${topLevelMenuItem.id}`"
          :class="
            !showRouteNames
              ? 'w-[63px] max-w-[63px] flex justify-center items-center'
              : ''
          "
        >
          <div
            @click="toggleSubMenu(topLevelMenuItem, topLevelMenuItem)"
            :class="[
              'menu-item',
              'top-level-menu-item',
              topLevelMenuItem.isOpen ? 'top-level-menu-item-active' : '',
              !showRouteNames
                ? 'w-[46px] max-w-[46px] bg-transparent grid-unset'
                : '',
            ]"
          >
            <div
              :class="
                !showRouteNames
                  ? 'menu-icon-wrapper m-auto'
                  : 'menu-icon-wrapper flex justify-center items-center'
              "
            >
              <img
                :src="getIcon(topLevelMenuItem.icon)"
                :id="`MENU-ICON-MENUID-${topLevelMenuItem.id}`"
                alt=""
                srcset=""
                class="max-w-[17px] menu-icon-img m-auto"
              />
            </div>
            <div :class="showRouteNames ? 'menu-text-wrapper' : 'hidden'">
              {{ topLevelMenuItem.name }}
            </div>

            <div
              :class="showRouteNames ? 'menu-toggle-arrow-wrapper' : 'hidden'"
            >
              <img
                v-if="topLevelMenuItem.children.length"
                src=""
                alt=""
                srcset=""
                class="menu-toggle-arrow top-level-menu-arrow"
                :data-menuid="topLevelMenuItem.id"
              />
            </div>
          </div>

          <ul
            v-show="topLevelMenuItem.children.length && topLevelMenuItem.isOpen"
            :class="[
              'menu-item',
              'menu-ident-1',
              !showRouteNames ? 'hidden' : '',
            ]"
          >
            <li
              v-for="submenu in topLevelMenuItem.children"
              :key="submenu.id"
              :id="`MENU-ID-${submenu.id}`"
            >
              <div
                @click="toggleSubMenu(submenu, topLevelMenuItem)"
                :class="[
                  'submenu-item',
                  submenu.isOpen ? 'submenu-item-active' : '',
                ]"
              >
                <div class="grid-spacer"></div>
                <div class="submenu-text-wrapper">
                  {{ submenu.name }}
                </div>

                <div class="menu-toggle-arrow-wrapper">
                  <img
                    v-if="submenu.children.length"
                    src=""
                    alt=""
                    srcset=""
                    class="menu-toggle-arrow"
                    :data-menuid="submenu.id"
                  />
                </div>
              </div>

              <ul
                v-show="submenu.children && submenu.isOpen"
                class="menu-ident-2"
              >
                <li
                  v-for="subSubmenu in submenu.children"
                  :key="subSubmenu.id"
                  :id="`MENU-ID-${subSubmenu.id}`"
                >
                  <div
                    @click="toggleSubMenu(subSubmenu, topLevelMenuItem)"
                    :class="[
                      'submenu-item',
                      subSubmenu.isOpen ? 'submenu-item-active' : '',
                    ]"
                  >
                    <div class="grid-spacer"></div>
                    <div class="submenu-text-wrapper">
                      {{ subSubmenu.name }}
                    </div>
                    <div class="grid-spacer"></div>
                  </div>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>

      <button
        v-if="showRouteNames"
        class="mt-auto absolute bottom-5 left-0 right-0 mx-auto inline-block text-white cursor-pointer logout-btn-text"
        @click="logout"
      >
        Sair
      </button>
      <button
        v-else
        class="mt-auto absolute bottom-5 right-0 mx-auto cursor-pointer logout-btn-text"
        style="left: calc(50% - 11px)"
        @click="logout"
      >
        <img src="../../assets/svgs/logout-icon.svg" alt="" srcset="" />
      </button>
    </div>
  </nav>
</template>

<script setup lang="ts">
import Logo from "../logo/Logo.vue";
import { useRouter, useRoute } from "vue-router";
import { ref, reactive, nextTick } from "vue";
import { onMounted, onUpdated } from "vue";

const menus: any[] = [
  {
    id: "1",
    name: "Início",
    route: "/",
    icon: "home",
    isChild: false,
    submenuDefault: function () {
      return this;
    },
    isOpen: false,
    isSelected: false,
    children: [],
    otherActiveRoutes: [],
  },
  {
    id: "2",
    name: "Enquadramentos",
    route: "/enquadramentos",
    submenuDefault: function () {
      return this.children[0].children[0];
    },
    icon: "enquadramentos",
    isChild: false,
    isOpen: false,
    isSelected: false,
    children: [
      {
        id: "2.1",
        name: "Lei 14.754",
        isChild: true,
        isOpen: false,
        isSelected: false,
        route: "",
        submenuDefault: function () {
          return this.children[0];
        },
        children: [
          {
            id: "2.1.1",
            name: "FIDC",
            route: "/enquadramentos",
            isChild: true,
            children: [],
            isOpen: false,
            isSelected: false,
          },
          {
            id: "2.1.2",
            name: "FIA",
            route: "/enquadramentos",
            isChild: true,
            children: [],
            isOpen: false,
            isSelected: false,
          },
          {
            id: "2.1.3",
            name: "ETF",
            route: "/enquadramentos",
            isChild: true,
            children: [],
            isOpen: false,
            isSelected: false,
          },
          {
            id: "2.1.4",
            name: "Demais Fundos",
            route: "/enquadramentos",
            isChild: true,
            children: [],
            isOpen: false,
            isSelected: false,
          },
        ],
      },
    ],
    otherActiveRoutes: [
      "/enquadramentos/:date",
      "/enquadramentos/detalhes/:id",
      "/relatorio-reprocessamentos",
    ],
  },
  {
    id: "3",
    name: "Cadastros",
    route: "/cadastros/fundos",
    submenuDefault: function () {
      return this.children[0];
    },
    icon: "cadastros",
    isChild: false,
    isOpen: false,
    isSelected: false,
    children: [
      {
        id: "3.1",
        name: "Fundos",
        route: "/cadastros/fundos",
        isChild: true,
        children: [],
        isOpen: false,
        isSelected: false,
        otherActiveRoutes: ["/cadastros/fundos/:mode/:id?"],
      },
      {
        id: "3.2",
        name: "Ativos",
        route: "/cadastros/ativos",
        isChild: true,
        children: [],
        isOpen: false,
        isSelected: false,
        otherActiveRoutes: ["/cadastros/ativos/:mode/:id?"],
      },
      {
        id: "3.3",
        name: "Tipos de Ativos",
        route: "/cadastros/asset-types",
        isChild: true,
        children: [],
        isOpen: false,
        isSelected: false,
        otherActiveRoutes: ["/cadastros/asset-types/:mode/:id?"],
      },
      {
        id: "3.4",
        name: "Tipos de CPR",
        route: "/cadastros/cpr-types",
        isChild: true,
        children: [],
        isOpen: false,
        isSelected: false,
        otherActiveRoutes: ["/cadastros/cpr-types/:mode/:id?"],
      },
    ],
    otherActiveRoutes: [],
  },
  // {
  // 	id: '4',
  // 	name: 'Gestão',
  // 	route: '/gestao',
  // 	submenuDefault: function () {
  // 		return this.children[0];
  // 	},
  // 	icon: 'gestao',
  // 	isChild: false,
  // 	isOpen: false,
  // 	isSelected: false,
  // 	children: [
  // 		{
  // 			id: '4.1',
  // 			name: 'Clientes',
  // 			route: '/gestao',
  // 			isChild: true,
  // 			children: [],
  // 			isOpen: false,
  // 			isSelected: false,
  // 		},
  // 		{
  // 			id: '4.2',
  // 			name: 'Usuários',
  // 			route: '/gestao',
  // 			isChild: true,
  // 			children: [],
  // 			isOpen: false,
  // 			isSelected: false,
  // 		},
  // 	],
  // },
];

const menu = reactive({
  isOpen: false,
  children: menus,
});

onMounted(() => {
  setAllArrowIcons();
  setTimeout(() => {
    handleActiveRoute();
  }, 100);
});

onUpdated(() => {
  setAllArrowIcons();
});

const handleActiveRoute = () => {
  const currentRoutePath = route.matched[0].path;

  const findFirstChildIndex = menus.findIndex(
    (el) =>
      el.route == currentRoutePath ||
      el.otherActiveRoutes?.includes(currentRoutePath) ||
      el.children?.find(
        (el2: any) =>
          el2.route == currentRoutePath ||
          el2.otherActiveRoutes?.includes(currentRoutePath)
      )
  );
  const findSecondChildIndex = menus[findFirstChildIndex]?.children?.findIndex(
    (el: any) =>
      el.route == currentRoutePath ||
      el.otherActiveRoutes?.includes(currentRoutePath)
  );
  let child;

  if (findSecondChildIndex > -1) {
    child = menu.children[findFirstChildIndex].children[findSecondChildIndex];
  } else if (findFirstChildIndex) {
    child = menu.children[findFirstChildIndex];
  }

  if (child) {
    if (findSecondChildIndex > -1) {
      console.log(findFirstChildIndex);

      toggleSubMenu(
        menu.children[findFirstChildIndex],
        menu.children[findFirstChildIndex],
        false
      );
    }
    // toggleSubMenu(child, child, false);
  } else if (
    !Object.keys(menu.children.filter((child) => child.isOpen == true)).length
  ) {
    toggleSubMenu(menu.children[0], menu.children[0], true);
  }
};

const toggleSubMenu = (
  menuItem: Record<string, any>,
  topLevelMenuItem: Record<string, any>,
  navigate: boolean = true
) => {
  if (Object.keys(menuItem).length === 0) return;

  const openPathToMenuItem = (
    targetItem: Record<string, any>,
    parentItem: Record<string, any>
  ): boolean => {
    parentItem.isOpen = true;

    if (parentItem.children && parentItem.children.length) {
      for (const child of parentItem.children) {
        if (child === targetItem) {
          child.isOpen = true;
          return true;
        }
        const found = openPathToMenuItem(targetItem, child);
        if (found) {
          child.isOpen = true;
          return true;
        }
        child.isOpen = false;
      }
    }
    return false;
  };

  const closeAllToggles = (menuObj: Record<string, any>) => {
    menuObj.isOpen = false;
    if (menuObj.children?.length) {
      menuObj.children.forEach((menuEl: any) => closeAllToggles(menuEl));
    }
  };

  if (!showRouteNames.value) {
    showRouteNames.value = true;
    if (!topLevelMenuItem.isOpen) {
      closeAllToggles(menu);
      openPathToMenuItem(topLevelMenuItem.submenuDefault(), topLevelMenuItem);
      if (navigate) navigateTo(topLevelMenuItem.submenuDefault().route);
      const iconImgEl = document.querySelector(
        `#MENU-ICON-MENUID-${topLevelMenuItem.id}`
      );
      iconImgEl?.setAttribute("src", getIcon(topLevelMenuItem.icon));
    }
    return;
  }

  if (menuItem == topLevelMenuItem) {
    if (menuItem.isOpen) {
      closeAllToggles(menu);
      const iconImgEl = document.querySelector(
        `#MENU-ICON-MENUID-${topLevelMenuItem.id}`
      );
      iconImgEl?.setAttribute("src", getIcon(topLevelMenuItem.icon, true));
      return;
    }

    if (!menuItem.isOpen) {
      closeAllToggles(menu);
      openPathToMenuItem(topLevelMenuItem.submenuDefault(), topLevelMenuItem);
      if (navigate) navigateTo(topLevelMenuItem.submenuDefault().route);
      const iconImgEl = document.querySelector(
        `#MENU-ICON-MENUID-${topLevelMenuItem.id}`
      );
      iconImgEl?.setAttribute("src", getIcon(topLevelMenuItem.icon));
      return;
    }
  }

  if (menuItem != topLevelMenuItem) {
    if (menuItem.isOpen) {
      if (!menuItem.route) {
        closeAllToggles(menu);
        const iconImgEl = document.querySelector(
          `#MENU-ICON-MENUID-${topLevelMenuItem.id}`
        );
        iconImgEl?.setAttribute("src", getIcon(topLevelMenuItem.icon, true));
        return;
      }
      return;
    }
    if (!menuItem.isOpen) {
      closeAllToggles(menu);

      if (menuItem.route) {
        openPathToMenuItem(menuItem, topLevelMenuItem);
        if (navigate) navigateTo(menuItem.route);
        return;
      }
      if (!menuItem.route) {
        openPathToMenuItem(menuItem.submenuDefault(), topLevelMenuItem);
        if (navigate) navigateTo(menuItem.submenuDefault().route);
        return;
      }
    }
  }
};

const routes = useRouter()
  .getRoutes()
  .filter((i) => i.meta.displayName);
const router = useRouter();
const route = useRoute();

const showRouteNames = ref(true);
const animationForwards = ref(false);
const animationReturn = ref(false);

const getIcon = (icon: string, forceInactive = false) => {
  if (icon) {
    const currentRouteIcon = route.meta.icon as string | "";

    const isActive = forceInactive
      ? ""
      : currentRouteIcon?.toLowerCase() === icon?.toLowerCase()
        ? "-active"
        : "";

    let imageUrl = new URL(
      `../../assets/svgs/${icon?.toLowerCase()}.svg`,
      import.meta.url
    ).href;
    imageUrl = imageUrl.replace(".svg", `${isActive}.svg`);
    return imageUrl as string;
  }
  return "";
};

const setAllArrowIcons = () => {
  const ArrowEls = document.querySelectorAll(
    ".menu-toggle-arrow"
  ) as NodeListOf<HTMLElement>;

  ArrowEls.forEach((arrowEl: HTMLElement) => {
    let fileName = "arrowdown-black";
    const menuDataId = arrowEl.dataset.menuid as string | undefined;
    if (menuDataId) {
      const menuItem = findMenuItemById(menuDataId);
      if (arrowEl.classList.contains("top-level-menu-arrow")) {
        if (menuItem.isOpen) {
          fileName = "arrowdown-white";
        } else {
          fileName = "arrowup-black";
        }
      } else {
        if (menuItem.isOpen) {
          fileName = "arrowdown-black";
        } else {
          fileName = "arrowup-black";
        }
      }

      const imageUrl = new URL(
        `../../assets/svgs/${fileName}.svg`,
        import.meta.url
      ).href;

      arrowEl.setAttribute("src", imageUrl);
    }
  });
};

const findMenuItemById = (id: string): Record<string, any> => {
  const mapArr = id.split(".");
  const level1Address = mapArr[0] as string;
  const level2Address = mapArr.length == 2 ? (mapArr[1] as string) : "";
  const level3Address = mapArr.length == 3 ? (mapArr[2] as string) : "";

  const menuItem = level3Address
    ? menu.children[parseInt(level1Address) - 1].children[
        parseInt(level2Address) - 1
      ].children[parseInt(level3Address) - 1]
    : level2Address
      ? menu.children[parseInt(level1Address) - 1].children[
          parseInt(level2Address) - 1
        ]
      : menu.children[parseInt(level1Address) - 1];

  return menuItem;
};

const toggleMenuVisibility = () => {
  animationForwards.value = true;
  if (!showRouteNames.value) {
    animationReturn.value = true;
  }

  animationForwards.value = false;
  animationReturn.value = false;
  showRouteNames.value = !showRouteNames.value;
};

const toggleMenu = () => {
  const navbar = document.querySelector(".navbar-custom");
  const menuIcon = document.querySelectorAll(".menu-icon span");

  if (navbar) {
    navbar.classList.toggle("navbar-active");
  }

  if (menuIcon) {
    menuIcon.forEach((i) => i.classList.toggle("menu-icon-active"));
  }
};

const navigateTo = (route: string) => {
  router.push(route);
  if (window.innerWidth < 1200) {
    menu.isOpen = false;
  }
};

const logout = () => {
  localStorage.removeItem("token");
  router.push("/login");
};
</script>

<style scoped lang="scss">
* {
  transition: 0.5s all ease-in-out !important;
}
.mobile-menu,
nav {
  font-family: "Albert Sans", system-ui !important;
}
.logout-btn-text {
  color: #2e2d2c !important;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
}

.navbar-close {
  box-shadow: -3px 5px 4px 0px #00000033;
}

.no-shadow {
  box-shadow: none !important;
}

.text-animation {
  span,
  img {
    visibility: hidden;
  }
}

.navbar-custom {
  background-color: #e1e0e1;
  color: #2e2d2c;
}

.top-level-menu-item-active {
  background-color: #2e2d2c !important;
  color: #fff !important;
}

.submenu-item-active {
  font-weight: bold;
}

.navbar-custom ol span {
  color: #2e2d2c !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 17px !important;
}

.menu-item {
  width: 204px !important;
  padding: 10px 12px;
}

ul.menu-item {
  padding: 0px;
}

.menu-ident-1 > .submenu-text-wrapper {
  display: grid;
  grid-template-columns: 2fr 10fr 1fr;
}

.menu-ident-2 > li > div .submenu-text-wrapper {
  padding-left: 30px;
}

.menu-ident-2 > li > div.submenu-item {
  display: grid !important;
  grid-template-columns: 2fr 10fr;
}

.top-level-menu-item {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #2e2d2c;
  background-color: #d4d4d4;
  border-radius: 10px;
  display: grid;
  grid-template-columns: 2fr 9fr 1fr;
  cursor: pointer !important;
  height: 39px;
  margin-bottom: 5px;
}

.submenu-item {
  display: grid;
  grid-template-columns: 2fr 9fr 1fr;
  cursor: pointer !important;
  margin-bottom: 5px;
  border-radius: 8px;
  min-height: 33px;
  padding: 7px 10px;
  font-size: 14px;
}

.submenu-item:hover {
  background-color: #d4d4d4;
}

.top-level-menu-item > div:nth-child(1) {
  align-items: center;
}

.top-level-menu-item > div:nth-child(2) {
  align-items: center;
}

.menu-toggle-arrow-wrapper {
  align-items: center;
  display: flex;
  justify-content: end;
}

.menu-toggle-arrow {
  width: 9px !important;
  height: 5px !important;
}

.grid-unset {
  grid-template-columns: unset !important;
}

@keyframes menu-slide {
  0% {
    transform: scaleX(0);
  }

  100% {
    transform: scaleX(1);
  }
}

@keyframes menu-slide-return {
  0% {
    transform: scaleX(0);
  }

  100% {
    transform: scaleX(1);
  }
}

@media (min-width: 1200px) {
  .mobile-menu {
    display: none;
  }
}

@media (max-width: 1199.98px) {
  .navbar-custom {
    padding: 3rem;
    min-height: 100vh !important;
    position: fixed;
    top: 0;
    bottom: 0;
    margin: auto;
    z-index: 999999;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translateX(-100%);

    .navbar-close {
      display: none;
    }
    li {
      font-size: 13px;
      flex-direction: column;
      text-align: center;
      padding: 0;
      margin-bottom: 4rem;
      img {
        width: 12px;
      }
    }
  }
  .mobile-menu {
    background-color: theme("colors.primary.DEFAULT");
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: fixed;
    top: 0;
    z-index: 99999;
    padding: 2.5rem 0;
    .menu-icon {
      width: 30px;
      height: 20px;
    }

    .menu-icon span {
      width: 35px;
      height: 4px;
      margin-left: 1rem;
      background-color: white;
      border-radius: 8px;
      display: block;
      z-index: 9;
      position: relative;
      &:nth-child(2) {
        margin-top: 6px;
        margin-bottom: 6px;
      }
    }
  }

  .navbar-active {
    transform: translateX(0%) !important;
    z-index: 99;
    width: 63px;
  }
  .menu-icon-active {
    transition: 0.4s ease-in-out;
    &:nth-child(2) {
      opacity: 0;
    }
    &:nth-child(1) {
      transform: rotate(53deg) translateY(11px) translateX(17px);
    }
    &:nth-child(3) {
      transform: rotate(-50deg);
    }
  }
}
</style>
