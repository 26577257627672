<template>
  <DataTable
    :value="data"
    tableStyle="min-width: 50rem"
    class="mt-3 clients-table max-h-[60vh]"
    scrollable
    scrollHeight="60vh"
  >
    <Column field="id" header="ID"></Column>
    <Column field="code" header="Tipo"></Column>
    <Column field="isCreditRight" header="É Direito Creditório?">
      <template #body="slotProps">
        <CheckBox :value="slotProps.data.isCreditRight" />
      </template>
    </Column>
    <Column field="reviewed" header="Revisado?">
      <template #body="slotProps">
        <CheckBox :value="slotProps.data.reviewed" />
      </template>
    </Column>
    <Column field="group" header="Grupo"></Column>
    <Column>
      <template #body="slotProps">
        <img
          src="@/assets/svgs/edit.svg"
          alt="Editar"
          class="cursor-pointer"
          @click="
            router.push({
              name: 'asset-type-create-edit',
              params: { id: slotProps.data.id, mode: 'editar' },
            })
          "
        />
      </template>
    </Column>
  </DataTable>

  <Paginator
    class="mt-5"
    :totalRecords="paginatorData.totalItems"
    :rows="paginatorData.itemsPerPage"
    @page="$emit('pageEvent', $event)"
  ></Paginator>
</template>

<script setup lang="ts">
import CheckBox from '../CheckBox.vue';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Paginator from 'primevue/paginator';
import { AssetType } from '@/core/interfaces/AssetTypes';
import { MetaValues } from '@/core/interfaces/Customers';
import { PropType } from 'vue';


import { useRouter } from 'vue-router';
const router = useRouter();

defineEmits(['pageEvent', 'frameworkDetails']);

defineProps({
	data: {
		type: Object as PropType<AssetType[]>,
		required: true,
	},
	paginatorData: {
		type: Object as PropType<MetaValues>,
		required: true,
	},
});
</script>

//manter sem scoped
<style>
.p-column-title {
  margin: auto;
}
.p-datatable-table tbody tr {
  &:nth-child(even) {
    background-color: theme("colors.tableStripe.1");
  }

  td {
    text-align: center !important;
  }

  &:last-child {
    td {
      &:first-child {
        border-bottom-left-radius: 8px;
      }
      &:last-child {
        border-bottom-right-radius: 8px;
      }
    }
  }
}

.p-datatable-table thead th {
  background-color: theme("colors.primary.DEFAULT");
  color: theme("colors.white.DEFAULT");
  padding-top: 1rem;
  &:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  &:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
}


</style>
