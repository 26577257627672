<template>
  <form @submit.prevent="handleFormSubmit">
    <div class="custom-grid create-fund-wrapper">
      <div class="flex flex-col font-semibold text-[18px] gap-1 input-wrapper">
        <label for="username">Código</label>
        <InputText
          disabled
          required
          v-model="code"
          class="global-input"
          :loading="loading"
        />
      </div>
      <div class="flex flex-col font-semibold text-[18px] gap-1 input-wrapper">
        <label for="username">CNPJ Emissor</label>
        <InputText
          disabled
          :loading="loading"
          v-maska
          data-maska="##.###.###/####-##"
          required
          v-model="issuerDocument"
          class="global-input"
        />
      </div>

      <!-- <div class="flex flex-col font-semibold text-[18px] gap-1 input-wrapper">
        <label for="username">Tipo</label>
        <InputText
          disabled
          :loading="loading"
          required
          v-model="type"
          class="global-input"
        />
      </div> -->
    </div>
    <hr class="mt-5" />
    <div class="flex justify-end gap-5">
      <Button
        label="Voltar"
        class="global-button secondary-button"
        @click="router.push({ name: 'ativos' })"
      />
      <Button
        :loading="loading"
        :disabled="
          loading || !selectedFundType || !type || !issuerDocument || !code
        "
        type="submit"
        :label="'Salvar'"
        class="global-button primary-button"
      />
    </div>
  </form>
</template>

<script lang="ts" setup>
import InputText from "primevue/inputtext";
import { ref, onMounted } from "vue";
import Button from "@/components/Button.vue";
import { vMaska } from "maska";
import { useToast } from "primevue/usetoast";
import { useRoute, useRouter } from "vue-router";
import { AtivoService } from "@/services/ativos/AtivosService";

const toast = useToast();

const router = useRouter();
const route = useRoute();

const code = ref("");
const issuerDocument = ref("");
const type = ref();

const loading = ref(false);

const selectedFundType = ref();

onMounted(() => {
  checkIsEditMode();
});

const checkIsEditMode = () => {
  if (route.params.mode === "editar") {
    getAssetById();
    return true;
  }
  return false;
};

const handleFormSubmit = async () => {
  loading.value = true;
  const payload = {
    code: code.value,
    issuerDocument: issuerDocument.value.replace(/\D/g, ""),
    type: type.value?.value,
    fundType: selectedFundType?.value?.value,
  };

  let response;

  if (!checkIsEditMode()) {
    response = await AtivoService.createAtivo(payload);
  } else {
    const fundId = route.params.id;
    response = await AtivoService.editAtivo(payload, fundId as string);
  }

  const createOrEditPast = !checkIsEditMode() ? "Criado" : "Editado";
  const createOrEditPresent = !checkIsEditMode() ? "Criar" : "Editar";

  if (!(response instanceof Error)) {
    toast.add({
      severity: "success",
      summary: `Ativo ${createOrEditPast} com sucesso!`,
      detail: "",
      life: 5000,
    });

    router.push({ name: "Ativos" });

    code.value = "";
    issuerDocument.value = "";
    type.value = "";
  } else {
    toast.add({
      severity: "error",
      summary: `Erro ao ${createOrEditPresent} fundo.`,
      detail: response,
      life: 5000,
    });
  }

  loading.value = false;
};

const getAssetById = async () => {
  const response = await AtivoService.getAtivoById(route.params.id as string);

  if (response && !(response instanceof Error)) {
    code.value = response.code;
    issuerDocument.value = response.issuerDocument;
    type.value = response.type.code;
  }
};
</script>

<style lang="scss" scoped>
.custom-grid {
  display: grid;
  grid-template-columns: 50% 50%;
  gap: 1.2rem;
  @media (max-width: 768px) {
    grid-template-columns: 100%;
  }
}
</style>
