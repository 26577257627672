<template>
  <div class=" xl:py-5 xl:px-5 content-wrapper" v-if="!loading">
    <div class="head flex items-center justify-between">
      <h2>Olá, {{ user?.name }}!</h2>
      <div class="flex items-center gap-2">
        <img src="../../assets/svgs/calendar.svg" alt="" />
        <span class="text-neutral-15 font-semibold">
          {{ formatDate(new Date().toString(), "dd-mm-yyyy") }}
        </span>
      </div>
    </div>
    <div class="flex xl:gap-5 xl:h-full xl:justify-items-start xl:items-stretch flex-col xl:flex-row content-wrapper ">
      <div class="flex flex-col justify-center items-center xl:justify-start xl:items-start">
        <CardExpandComponent title="Status diário">
          <template #content>
            <div class="grid mt-2">
              <CardV2
                :value="values?.fundsCount?.toLocaleString('pt-BR') || '0'"
                :showCircle="true"
                title="Total de Fundos"
                backgroundColor="rgba(0, 146, 200)"
                borderColor="white"
                outlineColor="rgba(0, 146, 200, 0.3)"
              />
              <CardV2
                :value="
                  values?.fundFrameworksCounts.countCompliant?.toLocaleString(
                    'pt-BR'
                  ) || '0'
                "
                :showCircle="true"
                title="Fundos Enquadrados"
                backgroundColor="rgba(115, 205, 31, 1)"
                borderColor="white"
                outlineColor="rgba(115, 205, 31, 0.3)"
                @click="handleFundsEnquadradosRoute"
              />
              <CardV2
                :value="
                  values?.fundFrameworksCounts.countNonCompliant?.toLocaleString(
                    'pt-BR'
                  )
                "
                :showCircle="true"
                title="Fundos Desenquadrados"
                backgroundColor="rgba(233, 88, 90)"
                borderColor="white"
                outlineColor="rgba(233, 88, 90, 0.3)"
                @click="handleFundsDesenquadradosRoute"
              />
              <CardV2
                :value="
                  formatDate(
                    values?.fundFrameworksCounts?.lastDate ?? '1901-01-01'
                  )
                "
                title="Último Enquadramento:"
                backgroundColor="rgba(0, 146, 200)"
                borderColor="white"
                outlineColor="rgba(0, 146, 200, 0.3)"
                :is-ultimo-enquadramento="true"
                @click="handleLastProcessingRoute"
              />
            </div>
          </template>
        </CardExpandComponent>
        <CardExpandComponent title="Alertas" class="mt-5">
          <template #content>
            <div class="xl:flex xl:items-center xl:gap-4 mt-2">
              <CardV2
                :value="String(values?.assetsTypesCount)"
                :showCircle="true"
                title="Ativos para Revisar"
                backgroundColor="rgba(245, 166, 35, 1)"
                borderColor="white"
                outlineColor="rgba(245, 166, 35, 0.3)"
                @click="
                  router.push({
                    name: 'asset-type',
                    query: { reviewed: 'false' },
                  })
                "
              />
              <CardV2
                :value="String(values?.cprTypesCount)"
                :showCircle="true"
                title="CPR para Revisar"
                backgroundColor="rgba(245, 166, 35, 1)"
                borderColor="white"
                outlineColor="rgba(245, 166, 35, 0.3)"
                @click="
                  router.push({
                    name: 'cpr-type',
                    query: { reviewed: 'false' },
                  })
                "
              />
            </div>
          </template>
        </CardExpandComponent>
      </div>
      <div class="w-full max-w-[1000px]">
        <!-- NOTE: Esse box tem pouca info pra deixar esticar com a tela, fica estranho -->
        <CardExpandComponent
          title="Distribuição de fundos"
          class="h-full relative mt-5 xl:mt-0"
          :showButton="true"
        >
          <template #content>
            <div class="funds-box-wrapper">
              <FundsBox
                v-for="(item, index) in fundsDistributionArray"
                :key="index"
                :title="item.type"
                :count="item.count"
                :distribution="{ value: item.distribution }"
                @click="
                  router.push({ name: 'Fundos', query: { type: item.type } })
                "
              />
            </div>
          </template>
        </CardExpandComponent>
      </div>
    </div>
    <CardExpandComponent title="Histórico dos últimos 5 dias:" class="mt-5">
      <template #content>
        <EnquadramentosTable v-if="!loading && frameworks" :data="frameworks" />
      </template>
    </CardExpandComponent>
  </div>
  <div class="container" v-else>
    <div class="grid grid-cols-2 gap-10">
      <Skeleton class="min-h-[200px]" />
      <Skeleton class="min-h-[200px]" />
      <Skeleton class="min-h-[200px]" />
      <Skeleton class="min-h-[200px]" />
    </div>
  </div>
</template>
<script setup lang="ts">
import { DashboardInterface } from '@/core/interfaces/Dashboard';
import { DashboarService } from '@/services/dashboard/Dashboard';
import { useToast } from 'primevue/usetoast';
import { onMounted, ref } from 'vue';

import { formatDate } from '@/common/formatDate';
import { User } from '@/core/interfaces/User';
import { FrameworkGrouped } from '@/core/interfaces/Framework';
import Skeleton from 'primevue/skeleton';
import CardExpandComponent from '@/components/CardExpandComponent.vue';
import CardV2 from '@/components/dashboard-card/CardV2.vue';
import FundsBox from '@/components/FundsBoxDashboard.vue';
import EnquadramentosTable from '@/components/enquadramentos/TableGrouped.vue';
import router from '@/router';

const values = ref<DashboardInterface>();
const frameworks = ref<FrameworkGrouped[]>();
const loading = ref(false);
const toast = useToast();
const user = ref<User>();
const lastDate = ref();
const fundsDistribution = ref<
  Record<string, { count: number; distribution: number }>
>({});
const fundsDistributionArray = ref<
  { type: string; count: number; distribution: number }[]
>([]);
onMounted(() => {
	getDashboardValues();
	setUser();
});

const handleLastProcessingRoute = () => {
	router.push({
		name: 'enquadramentos-lista',
		params: {
			date: lastDate.value.split('T')[0],
		},
		query: {},
	});
};

const handleFundsEnquadradosRoute = () => {
	router.push({
		name: 'enquadramentos-lista',
		params: {
			date: lastDate.value.split('T')[0],
		},
		query: {
			isCompliant: 'true',
		},
	});
};

const handleFundsDesenquadradosRoute = () => {
	router.push({
		name: 'enquadramentos-lista',
		params: {
			date: lastDate.value.split('T')[0],
		},
		query: {
			isCompliant: 'false',
		},
	});
};

const setUser = async () => {
	const userData = localStorage.getItem('user');
	user.value = JSON.parse(userData || '{}');
};
const getDashboardValues = async () => {
	loading.value = true;
	const response = await DashboarService.getDashboardValues();
	if (response && !(response instanceof Error)) {
		values.value = response;
		frameworks.value = response.fundsFrameworksGrouped;
		lastDate.value = response.fundsFrameworksGrouped[0].date;
		fundsDistribution.value = response.fundsDistribution as unknown as Record<
      string,
      { count: number; distribution: number }
    >;

		const objectToArray = Object.keys(fundsDistribution.value).map((key) => {
			return {
				type: key,
				count: (
          fundsDistribution.value[key] as {
            count: number;
            distribution: number;
          }
				).count,
				distribution: fundsDistribution.value[key].distribution,
			};
		});

		fundsDistributionArray.value = objectToArray;
		console.log(fundsDistributionArray.value);
	} else {
		toast.add({
			severity: 'error',
			summary: 'Erro ao buscar dados da dashboard',
			detail: '',
			life: 5000,
		});
	}
	loading.value = false;
};
</script>
<style lang="scss" scoped>
.title {
  font-size: 22px;
  color: #333333;
  font-weight: 400;
  padding-bottom: 10px;
  margin-top: 20px;
  &.history-title {
    margin-bottom: -40px;
  }
}
.head {
  padding-bottom: 20px;
  margin-bottom: 40px;
  border-bottom: 1px solid #8e8e8ec5;
  h2 {
    font-size: 26px;
    font-weight: 500;
    color: #333333;
  }
}
.grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  @media (max-width: 1027px) {
    grid-template-columns: repeat(1, 1fr);
  }
}
.funds-box-wrapper {
  display: flex;
  height: 88%;
  gap: 0.8rem;
  @media (max-width: 1600px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 0.5rem;
    row-gap: 0.5rem;
    justify-items: center;
    min-width: 100%!important;
  }
  @media (max-width: 1400.98px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 1199.98px) {

    grid-template-columns: repeat(2, 1fr);
   
  }
  @media (max-width: 992.98px) {

    grid-template-columns: repeat(1, 1fr);
    
  }


}

.content-wrapper {
  @media (max-width: 1200px) {
    max-width: calc(100% - 237px);
    overflow-x: auto;
    padding: 0;
    display: block;
    margin: auto;
  }
  @media (max-width: 767.98px) {
    max-width: 100%;
  }
}
</style>
