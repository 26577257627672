import { onMounted } from 'vue';
<template>
  <h2 class="text-text font-semibold text-[24px]">CPR</h2>
  <div class="mt-5 flex items-center gap-1 justify-end">
    <Button
      class="bg-primary hover:bg-secondary px-5 py-3 text-white border-solid border-transparent border-2"
      label="Filtrar"
      @click="visible = true"
    >
      <template #icon>
        <img src="@/assets/svgs/filter.svg" alt="filter icon" class="mr-3" />
      </template>
    </Button>
  </div>
  <TableLoading v-if="loading" class="mt-5" />

  <TableLoading v-if="loading" class="mt-5" />

  <div v-show="!loading && cprTypes.length > 0">
    <CprTypeTable
      :paginator-data="paginatorData"
      :data="cprTypes"
      v-show="!loading && cprTypes && paginatorData"
      @pageEvent="getCprTypes"
    />
  </div>
  <Dialog
    :draggable="false"
    v-model:visible="visible"
    modal
    header="Filtrar"
    class="modal-dialog-frameworks"
  >
    <hr class="mb-5" />

    <div class="flex flex-col mt-4">
      <label for="codigo"> Tipo </label>
      <InputText v-model="code" />
    </div>

    <div class="flex flex-col mt-4">
      <label for="description">Descrição</label>
      <InputText v-model="description" />
    </div>

    <div class="flex flex-col mt-4">
      <label for="codigo"> Revisado? </label>
      <Dropdown
        :editable="true"
        placeholder="Revisado?"
        v-model="selectedWasReviewed"
        optionLabel="name"
        :options="wasReviewedList"
        class="dropdown-custom"
      />
    </div>

    <hr class="my-5" />

    <div class="flex xl:justify-end gap-2">
      <Button
        @click="
          (fundDocument = ''), (description = ''), (code = ''), (type = { value: '', label: '' })
        "
        label="Limpar"
        class="rounded-lg text-white px-5 py-2 bg-neutral-4 hover:bg-primary-2"
      ></Button>
      <FilterButton
        label="Filtrar"
        class="bg-primary text-white hover:bg-secondary-2"
        @click="getCprTypes(), (visible = false)"
      />
    </div>
  </Dialog>
</template>

<script setup lang="ts">
import { CprType } from '@/core/interfaces/CprTypes';
import { CprTypesService } from '@/services/cpr-types/CprTypesService';
import { onMounted, ref } from 'vue';
import CprTypeTable from '@/components/cpr-types/Table.vue';
import { MetaValues } from '@/core/interfaces/Customers';
import Button from 'primevue/button';
import FilterButton from '@/components/Button.vue';
const cprTypes = ref<CprType[]>([]);
const paginatorData = ref({} as MetaValues);
const loading = ref(false);
import Dialog from 'primevue/dialog';
import InputText from 'primevue/inputtext';
import Dropdown from 'primevue/dropdown';
import TableLoading from '@/components/loading/TableLoading.vue';
import { useRoute } from 'vue-router';

const visible = ref(false);
const code = ref('');
const description = ref('');
const fundDocument = ref('');

const type = ref<{ value: string; label: string }>();

const wasReviewedList = ref([
	{ name: 'Sim', value: 'true' },
	{ name: 'Não', value: 'false' },
]);
const selectedWasReviewed = ref();
const route = useRoute();

onMounted(() => {
	if (route.query.reviewed) {
		selectedWasReviewed.value = wasReviewedList.value.find(
			option => option.value === route.query.reviewed
		);
	}
	getCprTypes();
});

const getCprTypes = async (pageData?: { page?: number }) => {
	loading.value = true;
	const filters = {
		code: code.value,
		description: description.value,
		reviewed: selectedWasReviewed.value?.value,
		page: typeof pageData?.page == 'number' ? pageData?.page + 1 : 1,
	};

	const response = await CprTypesService.getCprTypes(filters);

	if (response && !(response instanceof Error)) {
		cprTypes.value = response.data;
		paginatorData.value = response.meta;
	}
	loading.value = false;
};
</script>
