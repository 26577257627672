import { AxiosError } from "axios";
import RequestService from "@/services/ApiService";
import buildQueryString from "@/common/queryString";
import {
  FrameworkGroupedResponse,
  FrameworkResponse,
} from "@/core/interfaces/Framework";
import { FrameworkDetailsInterface } from "@/core/interfaces/FrameworkDetails";
import { XlsxDownloadInterface } from "@/core/interfaces/DownloadXlsx";
const requestService = new RequestService();

export class EnquadramentosService {
  public static async getFundFrameworks(filters?: {
    "fund.document"?: string;
    page?: string;
  }): Promise<FrameworkResponse | AxiosError> {
    let path = "/fund-frameworks";

    if (filters && filters["fund.document"]) {
      filters["fund.document"] = filters["fund.document"].replace(/\D/g, "");
    }

    if (filters) {
      path = buildQueryString(path, filters);
    }

    try {
      const response = await requestService.get(path);

      return response as FrameworkResponse;
    } catch (error: unknown) {
      return error as AxiosError;
    }
  }
  public static async getFundGroupedFrameworks(filters?: {
    page?: string;
    date?: string;
  }): Promise<FrameworkGroupedResponse | AxiosError> {
    const path = "/fund-frameworks/grouped";

    // if (filters) {
    //   path = buildQueryString(path, filters);
    // }

    try {
      const response = await requestService.get(path, { params: filters });

      return response as FrameworkGroupedResponse;
    } catch (error: unknown) {
      return error as AxiosError;
    }
  }

  public static async getFundFrameworkdDetails(
    id: string
  ): Promise<FrameworkDetailsInterface | AxiosError> {
    const path = `/fund-frameworks/${id}`;

    try {
      const response: FrameworkDetailsInterface =
        await requestService.get(path);

      return response;
    } catch (error: unknown) {
      return error as AxiosError;
    }
  }

  public static async processFundFramework(payload: {
    fundId: number;
    date: string;
  }): Promise<boolean | AxiosError> {
    const path = "/fund-frameworks/process";

    const response: boolean = await requestService.post(path, payload);

    return response;
  }

  public static async fundReprocessedCount(
    updatedAtLastHours: number
  ): Promise<number | AxiosError> {
    const path = `/fund-frameworks/reprocessed/count?updatedAtLastHours=${updatedAtLastHours}`;

    const response: number = await requestService.get(path);

    return response;
  }

  public static async fundsReprocessed(page: number = 1): Promise<
    | {
        meta: unknown;
        data: object[];
      }
    | AxiosError
  > {
    let path = `/fund-frameworks/reprocessed?page=${page}&sortBy=updatedAt:DESC`;

    const response = (await requestService.get(path)) as {
      meta: unknown;
      data: object[];
    };

    return { meta: response.meta, data: response.data };
  }

  public static async downloadXlsx(
    date: string,
    filters: { "fund.document"?: string }
  ): Promise<XlsxDownloadInterface | AxiosError> {
    let path = `fund-frameworks/export/xlsx/${date}`;

    if (filters && filters["fund.document"]) {
      filters["fund.document"] = filters["fund.document"].replace(/\D/g, "");
    }

    if (filters) {
      path = buildQueryString(path, filters);
    }
    try {
      const response = await requestService.get(path);
      return response as XlsxDownloadInterface;
    } catch (error: unknown) {
      console.error(error);
      return error as AxiosError;
    }
  }
}
