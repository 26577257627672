<template>
  <div
    class="flex justify-start items-center mt-5 px-5 py-3 rounded-md border-l-8 border-solid"
    :class="handleBg()"
  >
    <img :src="getImg" alt="icon" class="mr-4 w-5" />
    <h2 class="font-medium text-[1.3rem]">{{ message }}</h2>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';

const props = defineProps({
	message: {
		type: String,
		required: true,
	},
	status: {
		type: String,
		required: true,
	},
	isCompliant: {
		type: Boolean,
		required: true,
	},
});

const handleBg = () => {
	console.log(props.status);

	if (props.status === 'failed' || !props.isCompliant) {
		return 'bg-details-error border-details-error-border text-red-600';
	} else {
		return 'bg-details-success border-details-success-border text-details-text-success';
	}
};

const getImg = computed(() => {
	let icon = '';

	if (props.status === 'failed' || !props.isCompliant) {
		icon = 'alert';
	} else {
		icon = 'success';
	}

	const url = new URL(`../../assets/svgs/${icon}.svg`, import.meta.url).href;

	return url;
});
</script>
