<template>
  <div
    class="px-4 py-1 uppercase inline-block rounded-md font-bold"
    :class="handleStatus()?.class"
  >
    <span class="status">{{ handleStatus()?.label }}</span>
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
	status: {
		type: String,
		required: true,
	},
});

const handleStatus = () => {
	switch (props.status) {
	case 'finished':
		return { class: 'bg-secondary text-white', label: 'Finalizado' };
	case 'failed':
		return { class: 'bg-red-600 text-white', label: 'Falha' };
	case 'waiting':
		return { class: 'bg-neutral-5 text-secondary', label: 'Em espera' };
	case 'processing':
		return { class: 'bg-neutral-5 text-secondary', label: 'Processando' };

	default:
		break;
	}
};
</script>
<style scoped lang="scss">
.status {
  font-size: 15px;
}
</style>
