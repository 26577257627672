<template>
    <h2 class="text-[24px] font-bold text-black font-medium" v-if="$props.title">{{ $props.title }}</h2>
    <TableLoading v-if="loading" class="mt-5" />
    <DataTable v-if="!loading" :value="data" tableStyle="min-width: 50rem" class="mt-[15px] clients-table max-h-[60vh]">
        <Column field="fp_positionDate" header="Data Posição" style="min-width: 140px;">
            <template #body="slotProps">
                {{ formatDate(slotProps.data.fp_positionDate) }}
            </template>
        </Column>
        <Column field="f_document" header="CNPJ Fundo">
            <template #body="slotProps">
                <p style="min-width: 160px">
                    {{
                        slotProps.data.f_document.replace(
                            /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
                            "$1.$2.$3/$4-$5"
                    )
                    }}
                </p>
            </template>
        </Column>
        <Column field="a_code" header="Código do Ativo" style="min-width: 160px;"></Column>
        <Column field="a_name" header="Ativo" style="max-width: 150px;">
        </Column>
        <Column field="a_issuerDocument" header="CNPJ Emissor" style="min-width: 160px; max-width: 160px">
            <template #body="slotProps">
                <p>
                    {{
                         formatCNPJ(slotProps.data.a_issuerDocument)
                    }}
                </p>
            </template>
        </Column>
        <Column field="at_group" header="Grupo" style="max-width: 75px;">
        </Column>
        <Column field="at_code" header="Tipo" style="max-width: 75px;">
        </Column>
        <Column field="fp_netValue" header="Valor">
            <template #body="slotProps">
                {{ formatCurrency(Number(slotProps.data.fp_netValue)) }}
            </template>
        </Column>
    </DataTable>
</template>

<script setup lang="ts">
import { FundPositionByAssetType } from '@/core/interfaces/FundPositionByAssetType';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import { PropType } from 'vue';
import { formatDate } from '../../common/formatDate';
import { formatCurrency } from "../../common/formatCurrency";
import TableLoading from '@/components/loading/TableLoading.vue';

const props = defineProps({
    data: {
        type: Object as PropType<FundPositionByAssetType[]>,
        required: true
    },
    title: {
        type: String,
        required: true,
    },
    loading: {
        type: Boolean,
        required: false,
    }
})
const formatCNPJ = (s: any) => {
   return  typeof s == 'string' ? s.replace(
                            /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
                            "$1.$2.$3/$4-$5") : '-'
}
</script>

//manter sem scoped
<style>
.p-column-title {
    margin: auto;
}

.p-datatable-table tbody tr {
    &:nth-child(even) {
        background-color: theme("colors.tableStripe.1");
    }

    td {
        text-align: center !important;
    }

    &:last-child {
        td {
            &:first-child {
                border-bottom-left-radius: 8px;
            }

            &:last-child {
                border-bottom-right-radius: 8px;
            }
        }
    }
}

.p-datatable-table thead th {
    background-color: theme("colors.primary.DEFAULT");
    color: theme("colors.white.DEFAULT");
    padding-top: 1rem;

    &:last-child {
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
    }

    &:first-child {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
    }
}
</style>