import { AxiosError } from 'axios';
import RequestService from '@/services/ApiService';
import buildQueryString from '@/common/queryString';
import { Ativo, AtivosInterface } from '@/core/interfaces/Ativos';

const requestService = new RequestService();

export class AtivoService {
	public static async getAtivos(filters?: {
    code?: string;
    issuerDocument?: string;
    'type.code'?: string;
    'type.group'?: string;
    page?: number;
  }): Promise<AtivosInterface | AxiosError> {
		let path = '/assets';

		if (filters && filters['issuerDocument']) {
			filters['issuerDocument'] = filters['issuerDocument'].replace(/\D/g, '');
		}

		if (filters) {
			path = buildQueryString(path, filters);
		}

		try {
			const response = await requestService.get(path);

			return response as AtivosInterface;
		} catch (error: unknown) {
			return error as AxiosError;
		}
	}

	public static async createAtivo(payload: Ativo): Promise<Ativo | AxiosError> {
		const path = '/assets';

		try {
			const response = await requestService.post(path, payload);

			return response as Ativo;
		} catch (error: unknown) {
			return error as AxiosError;
		}
	}

	public static async editAtivo(
		payload: Ativo,
		id: string
	): Promise<Ativo | AxiosError> {
		const path = `/assets/${id}`;

		try {
			const response = await requestService.put(path, payload);

			return response as Ativo;
		} catch (error: unknown) {
			return error as AxiosError;
		}
	}

	public static async getAtivoById(id: string): Promise<Ativo | AxiosError> {
		const path = `/assets/${id}`;

		try {
			const response = await requestService.get(path);

			return response as Ativo;
		} catch (error: unknown) {
			return error as AxiosError;
		}
	}
}
