<template>
  <div class="funds-box flex flex-col">
    <div>
     
      <span class="funds-box__box-value">{{ count }}</span>
      <span class="funds-box__title">{{title}}</span>
        <div class="overflow-y-auto" v-for="(item, index) in distributionObjectToArray()" :key="index">
          <div class="funds-box__list-wrapper">
            <span class="funds-box__list-wrapper__title">Classe</span>
            <span class="funds-box__list-wrapper__title">Quantidade</span>
          </div>
          <div class="funds-box__list-wrapper">
            <span class="funds-box__list-wrapper__value">{{item.type}}</span>
            <span class="funds-box__list-wrapper__value">{{item.count}}</span>
          </div>
        </div>
    
        
    </div>  
    
    <div class="flex justify-center items-center h-100 mt-auto cursor-pointer">
      <img v-tooltip.left="'Ver detalhes'" src="../assets/svgs/abrir.svg" alt="arrow right" />
    </div>

  </div>

  
</template>


<script setup lang="ts">
import { defineProps } from 'vue';

const props = defineProps( {
	count: {
		type: Number,
		required: true
	},
	title: {
		type: String,
		required: true
	},
	distribution: {
		type: Object,
		required: true
	}
	
} );

const distributionObjectToArray = () => {
	const result = Object.keys(props.distribution.value).map(key => {
		return {
			type: key,
			count: props.distribution.value[key].count
		};
	});

	console.log('#', result);

	return result.sort((a, b) => b.count - a.count);

};

</script>

<style lang="scss" scoped>
.funds-box {
  background-color: theme('colors.white.DEFAULT');
  border: solid 1px theme('colors.neutral.2');
  border-radius: 8px;
  width: 180px;
  height: 100%;
  padding: 12px;
  margin-top: 1rem;
  @media (max-width: 1600px) {
    width: 100%;    
  }
  &__list-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;  
    &:first-child {
      margin-top: 1.5rem;
    }
    &__title {
      font-size: 14px;
      color: theme('colors.neutral.9');
    }
    &__value {
      font-size: 12px;
      color: #000;
      font-weight: 600;
    }
  }
  &__box-value {
    width: 113px;
    height: 33px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: theme('colors.secondary.6');
    font-size: 24px;
    color: theme('colors.secondary.2');
    font-weight: 600;
    border-radius: 5px;
    margin: auto;
  }
  &__title {
    font-size: 16px;
    color: theme('colors.primary.DEFAULT');
    font-weight: 600;
    text-align: center;
    display: block;
    margin-top: 1rem;
    position: relative;
    &:before {
      content: '';
      position: absolute;
      width: 40px;
      height: 1px;
      background-color: theme('colors.neutral.2');
      bottom: -3px;
      left: 0;
      right: 0;
      margin: auto;
    }
  }

}
</style>